import React from "react";
import { Helmet } from "react-helmet";

const Project = (props) => {
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
      </Helmet>
      <main className="portfolio-page style-1 mb-70">
        <section
          className="portfolio-projects section-padding style-1 bg-white mb-70"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="section-head text-center style-4 mb-60">
              <small className="title_small mb-70">404 Error- Page not found</small>
              <h2 className="mb-60">
                <span> 404 Error- Page not found</span>
              </h2>
              <p>
                Sorry but this page is not found please go to the home page for more details.
              </p>
            </div>
            
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
