import { useEffect, useState } from "react";
import { FloatingBanner } from "./FloatingBanner";
import useLocalStorage from "../useLocalStorage";

const ConsentBanner = () => {
  const [decisionMade, setDecisionMade] = useState(true);
  const [storage, setStorage] = useLocalStorage(
    "AARA_TECHNOLOGIES_CONSENT_COOKIE"
  );

  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  const sendConsent = (consent, value = "default") => {
    gtag("consent", value, consent);
  };

  useEffect(() => {
    if (storage) {
      sendConsent(storage);
      setDecisionMade(true);
    } else {
      setDecisionMade(false);
    }
  }, [setDecisionMade]);

  const handleDecision = (outcome) => {
    const consent = {
      functionality_storage: outcome,
      security_storage: outcome,
      ad_storage: outcome,
      analytics_storage: outcome,
      personalization_storage: outcome,
    };

    setStorage(consent);
    sendConsent(consent, "update");
    setDecisionMade(true);
  };

  return decisionMade ? (
    <></>
  ) : (
    <FloatingBanner
      acceptText="Yes"
      denyText="No"
      onAccept={() => {
        handleDecision("granted");
      }}
      onDeny={() => {
        handleDecision("denied");
      }}
    />
  );
};

export default ConsentBanner;
