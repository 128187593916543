import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomerpdevelopmentFAQ from "../../../FAQPages/ServicesFAQ/Customerpdevelopment";
import { baseUrl } from "../../../Config/baseUrl";
import axios from "axios";

const Project = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "ERP Software Design",
      type1: "Design",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/erp-1.webp",
      link: "",
      description:
        "Trust our top ERP Software to eliminate workflow pain points, implement new technologies & app.",
      tags: "ERP Software",
    },
    {
      name: "ERP Software Design 2",
      type1: "UI/UX DESIGN",
      description:
        "Trust our top ERP Software to eliminate workflow pain points, implement new technologies.",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/erp-2.webp",
      link: "",
      tags: "ERP Software",
    },
    {
      name: "ERP Software Design 2",
      type1: "UI/UX DESIGN",
      description:
        "Trust our top ERP Software to eliminate workflow pain points, implement new technologies.",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/erp-3.webp",
      link: "",
      tags: "ERP software",
    },
  ];

  const cards1 = [
    {
      name: "Custom ERP Development",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/web-design-icon.png",
      description:
        "We customize off-the-shelf ERP solutions by automating functioning, integrating data, customizing solutions, creating modules, and..",
    },
    {
      name: "Expert ERP Implementation",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/idea-research-icon.png",
      description:
        "Deploy and configure your custom networks, servers, security, and data management thought your entire organization with the..",
    },
    {
      name: "Easy Data Migrations",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/seo-icon.png",
      description:
        "Easily migrate data from their current ERP environment to a new ERP platform, using a collection of next-gen technologies and expert..",
    },
    {
      name: "ERP Integration Services",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/web-development-icon.png",
      description:
        "Leverage business-ready solutions and connect them with ERPs software or other enterprise systems to ensure zero business workflow..",
    },
    {
      name: "ERP System Configurations",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/time-data-icon.png",
      description:
        "We configure ERP software with specific roles, business intelligence solutions, fields, modifications, embellishment, addition of specific fields, and more..",
    },
    {
      name: "Data Warehousing",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/analysis-icon.png",
      description:
        "Leverage your central repository data warehousing systems to extract, transform, and load (ETL) information from disparate sources..",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${39}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main class="portfolio-page style-1">
        <section
          class="portfolio-projects section-padding style-1 bg-white"
          id="MixItUpFCF1BF"
        >
          <div class="container">
            <div class="section-head text-center style-4 mb-40">
              <small class="title_small">ERP Development Company</small>
              <h2 class="mb-20">
                <span> ERP Software Development </span> Company{" "}
              </h2>
              <p>
                We have an experienced team of production and inspection
                personnel to ensure quality.
              </p>
            </div>
          </div>
        </section>

        <section class="team section-padding style-6">
          <div class="content1">
            <div class="container">
              <div class="section-head text-center style-4 mb-60">
                <small class="title_small">ERP Software</small>
                <h2 class="mb-20">
                  Our <span>ERP Software Development</span>{" "}
                </h2>
                <p>
                  The Real Enterprise Resource Planning (ERP software
                  development company Noida) software incorporates the internal
                  and external data flows used by the organization into a single
                  and comprehensive solution. The ERP software Noida solution
                  requirement varies from business to business and based on this
                  requirement Aara Technologies ERP software development company
                  in Noida offers customized solutions to our clients. As one of
                  the leading ERP software company Noida, Lucknow, & etc. Aara
                  Technologies provide the best ERP software solution that helps
                  the organization to integrate all data and processes into a
                  single unified system. In our ERP systems, we use a single
                  unified database to store data for the various system modules.
                  Our ERP software solution(ERP software development company in
                  India) is web-based and it allows facilitates the organization
                  or firms have to real-time access to the system. Both
                  small-large sized organizations can be benefited from our
                  enterprise resource planning (ERP) software solution. Our ERP
                  solution integrates the practical systems used by a business
                  to manage their basic commercial functions like Finance &
                  Accounting, small business inventory software management,
                  purchasing, manufacturing, human resources, marketing
                  services, etc.
                </p>
                <p>
                  Aara Technologies ERP software operates as a medium for
                  organizations to increase their profits and business growth.
                  Our ERP software solutions standardize the use of one
                  application to run an entire business. Thus, the business can
                  reduce overall costs in the organization, thereby increasing
                  efficiency and cost-effectiveness and improving the company's
                  profits.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="team section-padding style-61">
          <div class="conten3">
            <div class="container">
              <div class="section-head text-center style-4 mb-30">
                <small class="title_small">Start Project</small>
              </div>
              <section class="portfolio style-1">
                <div className="content">
                  <div className="row mix-container">
                    {cards1.map((card) => (
                      <div
                        className="col-lg-4 mix"
                        style={{ marginBottom: "21px" }}
                      >
                        <Card>
                          <Card.Body>
                            <Card.Title>{card.name}</Card.Title>

                            <div class="service-icon">
                              <div class="service-icon-inner">
                                <LazyLoadImage src={card.image} alt="custom2" />
                              </div>
                            </div>

                            <Card.Text>{card.description}</Card.Text>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <section class="about section-padding style-4 mb-30">
          <div class="content frs-content" id="about" data-scroll-index="2">
            <div class="container">
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-6">
                  <div class="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/erp-1.webp"
                      alt="custom2"
                    />
                  </div>
                </div>
                <div class="col-lg-5 group">
                  <div
                    class="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div class="section-head style-4">
                        <small class="title_small">ERP Development</small>
                        <h2 class="mb-30">
                          <span> ERP </span> Software <span> Development </span>
                        </h2>
                      </div>

                      <p>
                        ERP software development helps organizations and
                        business firms manage their resources properly. The most
                        vital aim and vision is to be achieved through ERP
                        software development is to integrate back-office
                        business processes and facilitate the information flow
                        within an organization to take data-driven decisions
                        that are essential to running a business. With dedicated
                        ERP software development, an organization can
                        standardize and automate its processes to achieve a high
                        level of efficiency in its operations.
                      </p>
                      <p>
                        The ERP software development ensures that all employees
                        in an organization are working with the same data and
                        watching the same key performance indicators. At its
                        most basic level, ERP software development integrates
                        all the functional activities of CRM into one complete
                        system to streamline processes and information across
                        the entire business firm.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt="custom2"
              class="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              class="bubble"
            />
          </div>
          <div class="content sec-content">
            <div class="container">
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-5 order-2 order-lg-0 group">
                  <div
                    class="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div class="section-head style-4">
                        <small class="title_small">ERP software system</small>
                        <h2 class="mb-30">
                          Our <span> ERP system </span>
                        </h2>
                      </div>
                      <p class="text mb-40">
                        The central point of all ERP software systems is a
                        shared database that manages multiple functionalities
                        used by different enterprise functional units. The ERP
                        solution is a system that can combine and monitor all
                        the functions of an enterprise, such as warehouse
                        management, inventory management purchase management,
                        CRM management, customer support service, finance, HR,
                        supply chain management, logistics, software,(etc) on to
                        a single framework and thereby enabling business owners
                        to take balanced and informed decisions for the future.
                        .
                      </p>
                      <ul>
                        <li class="d-flex align-items-center op-4">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">Manages the visibility.</h6>
                        </li>
                        <li class="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">
                            Efficient enhancement of workforce.
                          </h6>
                        </li>
                        <li class="d-flex align-items-center op-4">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">
                            Integrate all business functionalities of every
                            department into a single system of units.
                          </h6>
                        </li>
                        <li class="d-flex align-items-center op-4">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">Integration of E-Commerce.</h6>
                        </li>
                        <li class="d-flex align-items-center op-4">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">We focused on IT costs.</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 order-0 order-lg-2">
                  <div class="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/erp-3.webp"
                      alt="custom2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="custom2"
              class="bubble2"
            />
          </div>
          <div class="content trd-content">
            <div class="container">
              <div class="row align-items-center justify-content-between">
                <div class="col-lg-6">
                  <div class="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/erp-2.webp"
                      alt="custom2"
                    />
                  </div>
                </div>
                <div class="col-lg-5 group">
                  <div
                    class="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div class="section-head style-4">
                        <small class="title_small">Aara Technologies</small>
                        <h2 class="mb-30">
                          Focus More With <span>ERP Services</span>{" "}
                        </h2>
                      </div>
                      <p class="text mb-40">
                        Aara Technologies develops custom ERP solutions that
                        enable administrators to take full control of the whole
                        business, from inventory and supply chain to retailers
                        and sales.
                      </p>
                      <ul>
                        <li class="d-flex align-items-center op-4">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">
                            Inventory Management ERP SOftware
                          </h6>
                        </li>
                        <li class="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">POS Management ERP SOftware</h6>
                        </li>
                        <li class="d-flex align-items-center op-4">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">Lead Management ERP SOftware</h6>
                        </li>
                        <li class="d-flex align-items-center op-4">
                          <ArrowRightIcon />
                          <h6 class="fw-bold">Sale Management ERP SOftware</h6>
                        </li>
                      </ul>
                      <Link
                        as={Link}
                        class="btn rounded-pill bg-blue4 fw-bold text-white mt-50"
                        to="/ourproject"
                      >
                        <small>Discovery Now</small>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              class="bubble"
            />
          </div>
          <div class="integration pt-60" data-scroll-index="3">
            <div class="section-head text-center style-4">
              <small class="title_small">erp softwares</small>
              <h2 class="mb-20">
                Integration With <span>Popular ERP system</span>{" "}
              </h2>
              <p>
                Notero intergrate with popular apps. Help you easy to connect
                and collaboration
              </p>
            </div>
            <div class="container">
              <div class="content">
                <div class="img text-center">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/web-design-icon.png"
                    alt="custom2"
                    class="mt-30 mb-20"
                  />
                  <h6>Custom ERP Development</h6>
                </div>
                <div class="img text-center">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/idea-research-icon.png"
                    alt="custom2"
                    class="mt-30 mb-20"
                  />
                  <h6>Expert ERP Implementation</h6>
                </div>
                <div class="img text-center">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/seo-icon.png"
                    alt="custom2"
                    class="mt-30 mb-20"
                  />
                  <h6>Easy Data Migrations</h6>
                </div>
                <div class="img text-center">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/web-development-icon.png"
                    alt="custom2"
                    class="mt-30 mb-20"
                  />
                  <h6>ERP Integration Services</h6>
                </div>
                <div class="img text-center">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/time-data-icon.png"
                    alt="custom2"
                    class="mt-30 mb-20"
                  />
                  <h6>ERP System Configurations</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="portfolio-projects section-padding style-1 bg-white">
          <div class="container">
            <div class="section-head text-center style-4 mb-40">
              <small class="title_small">ERP Development Company</small>
              <h2 class="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here our some projects are given.</p>
            </div>
            <div class="controls">
              <button>ERP Projects</button>
            </div>
            <section class="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className=" col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad !shadow-xl !shadow-gray-400">
                        <div className="img">
                          <LazyLoadImage
                            className=""
                            src={card.image}
                            alt="custom2"
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href="#">{card.title}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.title}
                          </small>
                          <div
                            className="overflow-hidden text text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                            }}
                          >
                            {card.content}
                          </div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {/* {card.tags} */}
                            </a>
                            <a href="#" className="me-1">
                              {/* {card.tags1} */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <CustomerpdevelopmentFAQ />
    </div>
  );
};

export default Project;
