import React, { useEffect, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";
import OdoodevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Odoodevelopment";

const Prestashop = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "SG App",
      type1: "WEBSITE DEVELOPMENT",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/sg-app.png",
      link: "",
      tags: "Odoo Development",
    },
    {
      name: "nrp-web",
      type1: "WEBSITE DEVELOPMENT",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nrp-web.png",
      link: "",
      tags: "Odoo Development",
    },
    {
      name: "RTI Web",
      type1: "WEBSITE DEVELOPMENT",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rti-web.png",
      link: "",
      tags: "Odoo Development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${46}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);

  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Odoo Development</small>
              <h2 className="mb-20">
                <span>Odoo Development</span> Company
              </h2>
              <p>
                We have an experienced team of Odoo Development that's provide
                best quality.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">Odoo Software</small>
                <h2>
                  <span>Odoo Development</span> Company
                </h2>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/odo1.webp"
                  alt=" Odoodevelopment"
                />
              </div>
              <p>
                Aara is an Odoo Development company and odoo is an enterprise
                development planning, It is an open-source solution coded on
                Python and designed for managing a business of an enterprise of
                any type of scale. We are offering Quick and effective
                development solutions.
              </p>
              <h5>Keypoints of Success in Odoo</h5>
              <p>
                There is a huge range of schemes and assets when odoo comes to
                development while you still building, and updating handling an
                eCommerce store and marketing on the side could be a lot to take
                on. You can stop all this and lose your business, we don't want
                bad for our customers, and for that, we are here to help you.
              </p>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <small className="title_small">Odoo ERP</small>
                    <h2 className="mb-30">
                      <span>Open Source Odoo ERP Software</span>
                    </h2>
                  </div>
                  <p>
                    Open source ERP system refers to an enterprise resource
                    planning software in which the public has been authorized
                    the source code of the system. Opensource ERP provides a
                    unified view of the core business process using any common
                    database.
                  </p>
                  <p>
                    We are developing Odoo services from scratch To finish. Are
                    you looking for the premier ERP solution for your business?
                    Want to track your everyday stats? Want to have an ERP that
                    fulfills your business requirements? Or you are afraid to
                    invest in the solution “ODOO” and forget all the complex
                    hurdles of business management. Odoo development company -
                    Oddo is the most robust growing Open Source business
                    solution in the world. It always allows you to make the best
                    decisions through its flexible and fully customizable
                    solution. We Aara technologies using Odoo development serve
                    any customer requirements for any business solutions. We are
                    providing full implementation, customization, development,
                    data migration, training, and support.
                  </p>
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/odoo-open-source.webp"
                    alt=" Odoodevelopment"
                  />
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" Odoodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <h2 className="mb-30">
                      Key Point For <span> Success In Odoo Development </span>
                    </h2>
                  </div>
                  <p className="mb-40 text">
                    There is a huge range of schemes and assets when odoo comes
                    to Digital Marketing, while you still building, and updating
                    handling an eCommerce store and marketing on the side could
                    be a lot to take on. You can stop all this and lose your
                    business, we don't want bad for our customers, and for that,
                    we are here to help you.
                  </p>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Opensource.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>OpenSource Platform</Card.Subtitle>
                          <Card.Text>
                            Odoo is an open-source platform, Odoo is an open
                            source process, to get the source code of which you
                            have to get a license, then you can develop it.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/Integrated-Scalability.svg"
                            className="mb-20 w-25"
                          />
                          <br />
                          <Card.Subtitle>Integrated Scalability</Card.Subtitle>
                          <Card.Text>
                            By Integrated Scalability users can understand all
                            complex problems easily in Odoo.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/easy-to-learn.svg"
                            className="mb-20 w-25"
                          />
                          <Card.Subtitle>Easy to learn</Card.Subtitle>
                          <Card.Text>
                            Odoo achieves an easy work module in business which
                            is used to fulfill all requirements of any business.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/Robust-Architecture.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>Robust Architecture</Card.Subtitle>
                          <Card.Text>
                            This is a strong designing process that helps your
                            business to grow in the market very fast.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/businesses-performance.svg"
                            alt=" Odoodevelopment"
                          />
                          <br />
                          <Card.Subtitle>
                            Improve businesses Performance
                          </Card.Subtitle>
                          <Card.Text>
                            It helps you to increase your business in
                            Accounting, e-Commerce, Points of sales, Inventory,
                            and many more. By use of Odoo, we can make our
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/Omni-Channel.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>Omni-Channel Reach</Card.Subtitle>
                          <Card.Text>
                            Odoo has highly centralized data and also has more
                            clients reach globally.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" Odoodevelopment"
              className="bubble2"
            />
          </div>

          <section className="mb-20 team section-padding style-6">
            <div className="content1">
              <div className="container">
                <div className="text-center section-head style-4 mb-30">
                  <h2>
                    Here Are The{" "}
                    <span> Main Features Of The App Development</span>
                  </h2>
                </div>
                <p>
                  We are not here to fulfill your present needs only we want to
                  upgrade you to the present need of customers and the market.
                  We are here to help you to update yourself on the market
                  needs. Odoo also helps you research the current or upcoming
                  market.
                </p>
              </div>
            </div>
          </section>

          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-mobile-app.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">App Development</small>
                      <h2 className="mb-30">
                        <span>Mobile App Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Nowadays most people are using Mobile phones. As compared
                      to desktop users or laptop user counting of Mobile phones
                      is more than the number of desktop users or laptop users.
                      We at Aara Technologies will help you unlock your handheld
                      devices which are used to lock your store with the help of
                      our Mobile Applications.
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We have already successfully Designed and Developed
                          Mobile Application for Odoo Website
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We cater the both platform : Android & IOS.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We have delivered successfully Developing and
                          deploying the Mobile Apps for Odoo.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We have Developed Customize solution on Odoo.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" Odoodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        PrestaShop development
                      </small>
                      <h2 className="mb-30">
                        <span> Odoo Migration</span> Services
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Aara technologies Migrate your Odoo Application in the
                      upcoming Odoo Version.
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We at Aara Technologies make it our day-to-day task of
                          handling the Odoo migration of our clients so it is
                          done properly.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Rigorous study of client's setup to develop the
                          roadmap for proper migration.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Providing Testing services before and after deployment
                          to ensure closure of any discrepancies.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Enabling Odoo migration and enhancements solutions to
                          our clients.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-migration.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" Odoodevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-sass.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Odoo Development</small>
                      <h2 className="mb-30">
                        <span>Odoo SAAS </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Cloud computing is currently taking 90% market. It brings
                      scalability, minimal maintenance, and uninterrupted access
                      to Odoo from anywhere. Odoo SaaS development is one of our
                      major focuses because it offers the optimal solutions to
                      every business especially for small to medium-sized
                      companies while being light on their pockets. We are
                      developing a Module that will be compatible with Odoo
                      SAAS. Our main focus is speared Odoo on the cloud platform
                      to grow our business in the market. We pioneered the
                      development of modules that transform your Odoo server
                      into a SaaS service provider with Odoo SaaS Kit Enabling
                      businesses to realize Odoo SaaS server at their end.
                      Innovating, Implementation, and Setting Up the Odoo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" Odoodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">ERP software</small>
                      <h2 className="mb-30">
                        <span> Odoo Customization </span> Services
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We know one size is not fit for all modules that's why we
                      use the more powerful module and handcrafted cogs in place
                      so you can use odoo as per requirements:
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We are taking care of all small or major Odoo
                          Customizations requests.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We also optimize the performance of all Odoo databases
                          to deliver highly efficient and faster performance.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Customize other third-party modules to make them more
                          attractive.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We are also providing services to append features to
                          Odoo Module according to our client's requirements.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We are building a module from Scratch for you.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-customization.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" Odoodevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-marketplace.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Odoo Development</small>
                      <h2 className="mb-30">
                        <span>Odoo Marketplace Pioneers </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      As a current condition of the market e-Commerce takes a
                      huge place in the market, Marketplace become more and more
                      to bring the local market. It will help you to keep all
                      local businesses in one place. Brick & Mortar shops are
                      realizing the power of eCommerce; more and more shops are
                      moving towards having their identity on the internet. We
                      here at Aara Technologies were the first to introduce a
                      Marketplace in Odoo.Frontrunners in implementing,
                      optimizing, and setting up the marketplace on your
                      Odoo.Odoo Marketplace offers a perfect solution to start
                      your own marketplace locally or internationally. All this
                      because Odoo is easily customizable and scalable to your
                      requirements; bring sellers to your online Marketplace
                      with Odoo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" Odoodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Odoo software</small>
                      <h2 className="mb-30">
                        <span> Odoo Themes </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      As we all know that designing is attractive to people, So
                      if your designing will be good more visitors will be to
                      your website. This is the place where the Odoo themes come
                      in.
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We develop themes for Both Odoo websites and their
                          Backend that are highly responsive.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Developing Odoo themes that add new features and
                          layers to the Odoo websites to make them more
                          engaging.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Optimized for performance, loading time, and SEO.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We also customize our themes to make them compatible
                          with third-party modules on your Odoo.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-theme-developement.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" Odoodevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-multiplateform.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Odoo Development Services
                      </small>
                      <h2 className="mb-30">
                        <span>Odoo Multi-Platform Connectivity </span>Services
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We all know that we can run easy websites on multiple
                      e-commerce platforms. It is also beneficial for all of us.
                      But the added management cost and resources needed can
                      sometimes be overwhelming.That is what Odoo can do for
                      you. And this is where we come in..
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We develop software that connects different e-commerce
                          platforms with ODOO so you can manage them even more
                          effectively.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Develop effective and easy-to-use Odoo Multi-Channel
                          Connectors so you can manage multiple websites at
                          once.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Studying closely the market trend and current
                          inclination towards various channels to develop the
                          right products for our clients.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We not only develop but also optimize and customize
                          the Odoo connectors as per our client's requirements.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" Odoodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Odoo POS</small>
                      <h2 className="mb-30">
                        <span> Odoo POS </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      POS is growing everywhere which is very fast and easy to
                      use and it is easy to manage also. Odoo POS is covering
                      these all topics very easily. This is giving you much more
                      facility to store your data and save you data too, It is
                      advanced enough to provide you with plethora of features
                      for your store management. And easy enough to customize
                      and mould it to your needs.
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We make Odoo POS more efficient and user-friendly; to
                          improve queue management in your store.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Providing POS Hardware and Software solutions and
                          alternatives to reduce dependency on IoT Box.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Developing customer-centric modules for Odoo POS.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Handling customization requests from clients all over
                          the world.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          We cater to clients wanting to Make Odoo POS Faster to
                          load with POS Speed Up development.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Develop Mobile Apps for Odoo POS to make it fit in
                          your hands.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/odoo-pos.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" Odoodevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=" Odoodevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Development Services
                      </small>
                      <h2 className="mb-30">
                        <span>PWA Development For Odoo Ecommerce </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      PWA Development for Odoo eCommerce is the perfect
                      combination of websites. It is providing more facilities
                      for other Mobile Applications also.
                    </p>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          One for the future of mobile technology; our aim is to
                          provide PWA benefits to our clients with the
                          development of PWA for Odoo e-commerce.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          The Odoo eCommerce PWA aims to improve the mobile
                          browsing experience of customers.
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          It provides features such as offline browsing, pushes
                          notification integration, cache support, etc.
                        </h6>
                      </li>
                      <li className="d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Provide solutions to customers seeking to enhance
                          speed, features, and usability on their website for
                          use on mobile devices.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" Odoodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <h2 className="mb-30">
                      We Have Worked{" "}
                      <span> Across Different Verticals And Industries </span>
                    </h2>
                  </div>
                  <p className="mb-40 text">
                    We Aara Technologies worked across multiple industries which
                    are given below.
                  </p>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/CRM.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>CRM</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/POS.svg"
                            className="mb-20"
                          />
                          <br />
                          <Card.Subtitle>POS</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Sales.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Sales</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Project.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Project</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Timesheet.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Timesheet</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Help-Desk.svg"
                            className="mb-20 "
                          />
                          <Card.Subtitle>Help Desk</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Inventory.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Inventory</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/MRP.svg"
                            className="w-40 mb-20"
                          />
                          <br />
                          <Card.Subtitle>MRP</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Purchase.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Purchase</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/E-commerce.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>E-commerce</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Website.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Website</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Invoicing.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Invoicing</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Accounting.svg"
                            className="mb-20 w-25"
                          />
                          <Card.Subtitle>Accounting</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Email-Marketting.svg"
                            className="w-40 mb-20"
                          />
                          <br />
                          <Card.Subtitle>Email Marketting</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Marketting-Automation.svg"
                            className="mb-20 "
                          />
                          <Card.Subtitle>Marketting Automation</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/IOT.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>IOT</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/Manufacturing.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Manufacturing</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/icons-odoo/vertical%26industries/PLM.svg"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>PLM</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" Odoodevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row justify-content-between">
                <Card>
                  <Card.Body>
                    <Card.Title>
                      <h2>
                        We are ready to work and build the on-demand solution
                        for your business.
                      </h2>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" Odoodevelopment"
              className="bubble"
            />
          </div>

          {/*    <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="section-head style-4">
                  <small className="title_small">
                    <strong>FAQ's</strong>
                  </small>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h5>
                        Is Odoo opensource?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, the Odoo community edition is open source. Odoo comes in two editions
                      <ul><li>Community edition – Free of cost and Open source</li>
                        <li>Enterprise edition – Here pricing depends on region, users, apps, and hosting</li></ul>

                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <h5>
                        is Odoo offering cloud infrastructure?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes,Odoo provides two options
                      <ol><li>Odoo online</li>
                        <li>Odoo.sh</li></ol>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h5>
                        Is Odoo suitable for eCommerce & and multivendor?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, Odoo has inbuilt eCommerce and CMS. Odoo is not just an ERP platform but includes a complete eCommerce platform as well.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div> */}
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Odoo Development</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt=" Odoodevelopment"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <OdoodevelopmentFAQ />
    </div>
  );
};

export default Prestashop;
