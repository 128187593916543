import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";
import WebsitedesignanddevelopmentFAQ from "../../../FAQPages/ServicesFAQ/Websitedesignanddevelopment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Project = (props) => {
  const [meta, setMeta] = useState();

  const [cards, setCards] = useState();

  const ProjectData = (page_title) => {
    axios
      .get(`${baseUrl}/blog/project-image-api/?project_id=${22}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          // Authorization: `Token ${localStorage.getItem("token")}`,
          /* "Access-Control-Allow-Origin": "*" */
        },
      })
      .then((res) => {
        setCards(res.data?.data || []);
      })
      .catch((err) => {
        toast.error(
          "Error fetching FAQs",
          err?.response?.data?.message || "An error occurred"
        );
      });
  };

  // const cards = [
  //   {
  //     name: "Clop Solution",
  //     type1: "WEBSITE DEVELOPMENT",
  //     image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/clop-solution.png',
  //     link: "",
  //     tags: "web design",
  //   },
  //   {
  //     name: "Aditya gold",
  //     type1: "WEBSITE DEVELOPMENT",
  //     image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/aditya-gold.png',
  //     link: "",
  //     tags: "web design",
  //   },
  //   {
  //     name: "Bugudanac Web",
  //     type1: "Web DEVELOPEMNT",
  //     image: 'https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/bugudanac.png',
  //     link: "",
  //     tags: "web design",
  //   },
  // ];

  useEffect(() => {
    ProjectData();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${62}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Web development</small>
              <h2 className="mb-20">
                <span> Website Development </span> Company{" "}
              </h2>
              <p>
                We have an experienced team of Web design & personnel to ensure
                quality.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">
                  Best Website Design & Development Company
                </small>
                <h2 className="mb-20">
                  <span>Website Design & Development</span>{" "}
                </h2>
                <p>
                  Aara Technologies is counted as the Best Website Development
                  Company in Noida, Lucknow, and entire India. We offer a wide
                  range of customized designs for various kinds of websites. We
                  deal in every type of website design and development such as
                  static and dynamic web design. We have successfully delivered
                  many websites like news portals, school websites, e-commerce
                  websites and etc.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/web-process.webp"
                  alt="Website Development"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="mb-10 img mb-lg-0">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h4>
                            Our Key Focus Area in Web site
                            <br /> Development Service
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Attractive & Robust Design
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">Responsiveness</h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">Logo Design</h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Unique Content writing
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Cyber attacks preliminary checks before live
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Database performance tuning to decrease load
                                time
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Search Engine Optimization before going live.
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Cost-effective development.
                              </h6>
                            </li>
                            <li className="d-flex align-items-center ">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Banners and Online advertisement
                              </h6>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-5 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">Web Development</small>
                        <h6>
                          Aara Technologies is Recognised as one of the Best
                          Website Development Companies in NOIDA and the entire
                          India.
                        </h6>
                        <br />
                      </div>

                      <p>
                        Aara Technologies is counted as the Best Website Our
                        website design services generate more leads, and sales
                        and help you to grow your business. That is beautiful,
                        works perfectly, and focuses only on the user experience
                        for Growing your business. We are India's trusted Web
                        development company catering to variegated demands in
                        different arrays of web and ui based app development.
                      </p>

                      <ul>
                        <li className="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <h6>
                            To get maximum exposure and visibility on the World
                            Wide Web.
                          </h6>
                        </li>
                        <li className="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <h6>
                            To get more leads for marketing of products and
                            services and to reach out to the target market
                            quickly.
                          </h6>
                        </li>
                        <li className="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <h6>
                            Brand awareness for a new business in very less
                            time.
                          </h6>
                        </li>
                        <li className="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <h6>
                            A website promotes your business on a 24X7 basis
                          </h6>
                        </li>
                      </ul>
                      <a
                        href="#"
                        className="text-white btn rounded-pill bg-blue4 fw-bold mt-50"
                      >
                        <Link as={Link} to="/ourproject" className="text-white">
                          {" "}
                          <small> View Our Work </small>
                        </Link>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt="line"
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-5 order-lg-0">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">Web development</small>
                        <h2 className="mb-40">
                          <span> Website design</span>
                        </h2>
                      </div>
                      <p>
                        Web designing is very much important for business owners
                        because it would help them promote their products and
                        services by making people aware of their products and
                        services. Having a website for business, a business
                        owner can expect various benefits like:
                      </p>
                      <p className="mb-20 text">
                        But it is very difficult to achieve all these things
                        without a professional website designing company. A
                        website can make a company famous fast if the perfect
                        website designing services are taken care of properly.
                        And as the business grows bigger, it becomes a need for
                        the website to grow and develop and that is where
                        website designing services come in very handy.
                      </p>
                      <a
                        href="#"
                        className="text-white btn rounded-pill bg-blue4 fw-bold mt-50"
                      >
                        <Link as={Link} to="/ourproject" className="text-white">
                          {" "}
                          <small> View Our Work </small>
                        </Link>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h4>Web design benefits</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li className="d-flex align-items-center mb-30 ">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Help them promote their products and services.
                              </h6>
                            </li>
                            <li className="d-flex align-items-center mb-30 ">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                To get more leads for marketing of products and
                                services and to reach out to the target market
                                quickly.
                              </h6>
                            </li>
                            <li className="d-flex align-items-center mb-30">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                people aware of their products and services
                              </h6>
                            </li>
                            <li className="d-flex align-items-center mb-30">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                A website promotes your business on a 24X7 basis
                              </h6>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Website Development"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h4>
                            Our <br /> Development work
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                We helped many big and small businesses to set
                                up their presence on the web
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                We are having our offices in NOIDA, Delhi NCR,
                                Lucknow and entire India
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                We offer our services worldwide
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Website design professionals at the company are
                                highly skilled
                              </h6>
                            </li>
                            <li className="mb-20 d-flex align-items-center">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Always use the latest technologies to create the
                                most effective website
                              </h6>
                            </li>
                            <li className="d-flex align-items-center ">
                              <ArrowRightIcon />
                              <h6 className="fw-bold">
                                Use better Strategy to developed any site
                              </h6>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-5 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">Aara Technologies</small>
                        <h2 className="mb-30">
                          Focus More With <span>Web design</span>{" "}
                        </h2>
                      </div>
                      <p>
                        In the last few years, Aara Technologies has helped many
                        big and small businesses to set up their presence on the
                        web by providing professional and innovative web
                        designing services. We are having our offices in NOIDA,
                        Delhi NCR, and Lucknow but we are not limited only to
                        these physical locations. We offer our services
                        worldwide. Our main areas of operations are India,
                        Singapore, Dubai, the USA, the UK, Rwanda, Cyprus, and
                        more locations.
                      </p>

                      <p className="mb-40 text">
                        The website design professionals at the company are
                        highly skilled, and experienced and possess the
                        knowledge to create an attractive website with the best
                        features. They always use the latest technologies to
                        create the most effective website. All the latest tools
                        are used in creating the website. These are some of the
                        reasons why we are considered the best website design
                        company in Noida.
                      </p>

                      <a
                        href="#"
                        className="text-white btn rounded-pill bg-blue4 fw-bold mt-50"
                      >
                        <Link as={Link} to="/ourproject" className="text-white">
                          {" "}
                          <small> View Our Work </small>
                        </Link>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bob"
              className="bubble"
            />
          </div>
          <div className="integration pt-30" data-scroll-index="3">
            <section className="team section-padding style-6">
              <div className="content1">
                <div className="container">
                  <div className="text-center section-head style-4 mb-30">
                    <small className="title_small">
                      Website Design & Development
                    </small>
                    <h2>
                      <span>Website Design & Development</span>{" "}
                    </h2>
                  </div>
                  <p>
                    We as a Website development company situated in Noida also
                    offers several other IT services such as eCommerce solution,
                    Mobile App development, website maintenance, customized
                    software development, and digital marketing services. We
                    tend to provide you with the best customer support services,
                  </p>

                  <p>
                    So, hire a web designing company today to design a website
                    for your business. to increase your online presence and get
                    more business opportunities. Aara Technologies is one of the
                    best options available to design your website a success. We
                    help you get the best possible customization for your
                    website. The team of experts provides you with web
                    designing, hosting, SEO services, domain registration,
                    domain management, and much more.
                  </p>
                  <p>
                    Our eCommerce Web designing services can also help you to
                    create an online store for your products. This is a very
                    effective way to sell your products. With proper web design
                    and interactive features, your customers can order your
                    products through the website without actually going to the
                    store.
                  </p>
                  <p>
                    Parallax designing in websites is mostly used nowadays. This
                    is a very interactive feature of an attractive website
                    because it makes the user feel as if he/she is being
                    transported to different places, with the help of his
                    website. It also enables users to take decisions.
                  </p>
                  <p>
                    Our best Web designing services also include designing
                    websites based in various languages. These are effective
                    ways of reaching out to a wider audience and getting your
                    website known to a wider audience. This is a very important
                    and effective way if you want to reach out to a larger
                    audience.
                  </p>
                </div>
              </div>
            </section>
            <div className="integration pt-60" data-scroll-index="3">
              <div className="text-center section-head style-4">
                <small className="title_small">Steps For Done</small>
                <h2 className="mb-20">
                  Our <span> Working Process</span>{" "}
                </h2>
              </div>
              <div className="container">
                <div className="content">
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/provisioning.png"
                      alt="Website Development"
                      className="mt-30"
                    />
                    <h3 className="text-center">1. Provisioning</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">2. Strategy</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                      alt="Website Development"
                      className="mt-30"
                    />
                  </div>
                  <div className="img">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/design%26developement.png"
                      alt="Website Development"
                      className="mt-30"
                    />
                    <h3 className="text-center">3. Development</h3>
                  </div>
                  <div className="img">
                    <h3 className="text-center">4. Go Live</h3>
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/go-live.png"
                      alt="Website Development"
                      className="mt-30"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Web development</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here our some projects are given below.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards?.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt="Website Development"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a>{card.title}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.content}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <WebsitedesignanddevelopmentFAQ />
    </div>
  );
};

export default Project;
