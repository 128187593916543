import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Faq from "../../FAQ";
import FlutterdevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Flutterdevelopment";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";

const Flutter = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Taiton app",
      type1: "App Development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/taiton-app.png",
      tags: "FLutter App",
    },
    {
      name: "bus App",
      type1: "App Development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/buss.png",
      tags: "FLutter App",
    },
    {
      name: "sg-app",
      type1: "App Development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/sg-app.png",
      tags: "FLutter App",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${58}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>Flutter App Development</span> Company
              </h2>
              <p>
                Aara technologies is the best Flutter App Development Company
                which is knowledge and adept iOS Developer to having hands on
                experience.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">App Development</small>
                <h2 className="mb-20">
                  <span>Flutter App Development</span> Company
                </h2>
                <p>
                  We are the topmost market leader of the Flutter App
                  development company in Noida, Lucknow & entire India. Flutter
                  is an open-source UI software development kit created by
                  Google. It is used to develop cross-platform applications for
                  Android, iOS, Linux, Mac, Windows, Google Fuchsia, and the web
                  from a single codebase. The first version of Flutter was known
                  as codename "Sky" and ran on the Android operating system.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/flutter.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
          <div className="conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  Thinking Of <span>Building A New Flutter App?</span>
                </h2>
                <p>
                  As we know that all businesses need a Mobile Application that
                  can make business easier and save more time too. If you also
                  thinking to develop an application for your business then
                  Flutter development is best it is used to develop
                  cross-platform apps which support android IOS, Linux, Mac,
                  windows, and all. Flutter will give the best look to your
                  application with advanced features and technology. Aara
                  technologies are the best flutter app development company.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-project.webp"
                  alt="Flutter"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <h2 className="mb-20">
                  Here Are The Main Features Of The Flutter App Development
                </h2>
                <p className="mb-40">
                  Top characteristics of flutter app or Cross- Platform App
                  Development Framework.
                </p>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/andriod-app-development.webp"
                      alt="Flutter"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Expressive, Beautiful UIs</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Flutter is supporting Google’s UI Tool kits by using this
                      developers are designing a very much beautiful and
                      attractive design for websites. Design is everything on a
                      website if any things look beautiful audience attracts to
                      that only so the website is the same thing if your website
                      looks good then more audience come and stay long time that
                      will automatic help to generate revenue for your business.
                      If you are running an e-Commerce business then must be
                      your website design should be unique and available with
                      advanced features in the market because everyone looking
                      for updating things .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Flutter"
              className="bubble"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Native Performance</span>
                      </h2>
                    </div>
                    <p className="text">
                      Flutter are having the functionality of Native Performance
                      which enables compilation to native 32-bit and 64-bit ARM
                      code for iOS and Android." Flutter shows better
                      performance due to seamless integration with the mobile OS
                      platform and the graphic rendering at a few times higher
                      frequency than React Native.
                    </p>
                    <p>
                      The flutter app looks good on the up-to-date operating
                      system as they do on the older version. the apps look and
                      behave similarly across iOS and Android – but thanks to
                      Material Design and Cupertino widgets, they can also
                      imitate the platform design itself.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt="Flutter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Flutter"
              className="bubble"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/flutter2.webp"
                      alt="Flutter"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Fast Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Flutter app is faster than another Mobile Application
                      Framework.which is hot/reloaded features, If I am talking
                      about java and Kotlin, Java and Kotlin are also having
                      similar performance indications and are the best options
                      for Android development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Flutter"
              className="bubble"
            />
          </div>

          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <h2 className="mb-30">
                      <span>Start Your Business With Our Creative Apps</span>
                    </h2>
                  </div>
                </div>
                <p>
                  We have already delivered multiple application projects. Our
                  creative web design will help you to generate more revenue for
                  your business from the current Market.
                </p>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Flutter"
              className="bubble2"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Flutter App UI/UX Design</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Flutter applications are having good UI/UX design. Majorly
                      nowadays customers are using mobile phones. Mobile phones
                      are well known to create a great User Experience (UX).
                      Mobile-related technologies are getting more popular
                      worldwide with their advancement. The expectation of
                      mobile users is getting higher and higher these days. They
                      just want things to be done more easily and conveniently
                      without any issues. This will make the Android
                      applications more pivotal in reference to the significance
                      of UI/UX design.UI/UX design of the Flutter application
                      enhances the experience of the user and hence plays a
                      vital role in the success of any mobile app.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/flutter-ui.webp"
                      alt="Flutter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Flutter"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt="Flutter"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Enterprise App Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      By using Flutter app development services we can design or
                      develop attractive mobile applications. Enterprise
                      Application Development is a complex process of creating
                      applications for business purposes. They are complex,
                      customized for critical business requirements, and can be
                      deployed on the cloud, on a variety of platforms across
                      corporate networks, intranet, etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Flutter"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>App Consultation</span>
                      </h2>
                    </div>
                    <p className="text">
                      App consultation is aimed at providing expert advice on
                      mobile development projects. With 8+ years of experience
                      working on mobile applications, ScienceSoft advises on
                      device compatibility, conducts audits of apps, and designs
                      modernization and development from scratch of innovative
                      apps for competition.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/flutter3.webp"
                      alt="Flutter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Flutter"
              className="bubble2"
            />
          </div>
          <div className="mb-40 content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt="Flutter"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Widget Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Widgets in the mobile application are miniature app views.
                      App widgets can be embedded in other applications as well.
                      It also receives periodic updates. Mobile App Widgets have
                      also a second name i.e Graphical Control Elements. It is
                      basically the shortcut icons or the controls that are
                      displayed on the home screen of any application. It works
                      even after minimizing the application. Widgets act like a
                      direct gateway into the applications. Some of the most
                      common widgets are Whether, Calendar, Clock, Order
                      tracking, etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Flutter"
              className="bubble"
            />
          </div>
        </section>
        <section className="team section-padding style-6">
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>Technology</span>
                </h2>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/app-technologies.webp"
                  className="mb-30"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    Serving Middle East Countries With Our Android Apps
                  </span>
                </h2>
                <p>
                  Aara Technologies are providing application development
                  services globally ,Aara Technologies are providing all
                  services below mentioned countries:
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/Available-Countries.webp"
                  alt="Flutter"
                />
              </div>
            </div>
          </div>
          <div className="mb-40 conten">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <h2 className="mb-20">
                  <span>
                    We Have Worked Across Different Verticals And Industries
                  </span>
                </h2>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/food-grocery.svg"
                          className="mb-20"
                        />
                        <Card.Subtitle>Food & Grocery</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/retail.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Retail</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fmcg.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>FMCG</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/real-estate.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Real Estate</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/construction.svg"
                          className="w-40 mb-20"
                          alt="Flutter"
                        />
                        <Card.Subtitle>Construction</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fashion-apparel.svg"
                          alt="Flutter"
                        />
                        <Card.Subtitle>Fashion & Apparel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/hotel.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Hotel</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/healthcare.svg"
                          className="w-40 mb-20"
                        />
                        <br />
                        <Card.Subtitle>Healthcare</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/telecom.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Telecom</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/fintech.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Fintech</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/manufacturing.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Manufacturing</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <LazyLoadImage
                          src="https://aaratech.s3.ap-south-1.amazonaws.com/ios/industries/automotive.svg"
                          className="w-40 mb-20"
                        />
                        <Card.Subtitle>Automotive</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>

        <Faq />
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our <span>projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt="Flutter"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <FlutterdevelopmentFAQ />
    </div>
  );
};

export default Flutter;
