import React, { useEffect, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LearningmanagementsystemFAQ from "../../../FAQPages/ServicesFAQ/Learningmanagementsystem";
import { baseUrl } from "../../../Config/baseUrl";
import axios from "axios";

const LMS = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Product Inventory mangement Software",
      type1: "Software development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-inventory-management.jpg",
      link: "",
      description:
        "Product Inventory management Software is the part of cloud based erp software. ",
      tags: "Cloud based software",
    },
    {
      name: "Product human resource management App",
      type1: "Software DEVELOPMENT",
      description:
        "Product human resource management App is the part of cloud based hrm software.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-human-resource-management.jpg",
      link: "",
      tags: "Cloud based software",
    },
    {
      name: "Product multi level marketing software App",
      type1: "Software DEVELOPEMNT",
      description:
        "Product multi level marketing App is the part of cloud based mlm software",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-multi-level-marketing.jpg",
      link: "",
      tags: "Cloud based software",
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${41}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">LMS</small>
              <h2 className="mb-20">
                <span> LMS Software Development </span> Company{" "}
              </h2>
              <p>
                We have an experienced team of LMS Software Development to
                provide best software solutions.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">LMS Software</small>
                <h2 className="mb-20">
                  <span>Learning Management System Software </span>
                </h2>
                <p>
                  A Learning Management System is the software that companies
                  use to develop, deliver, and track training for their
                  employees, clients, and partners. Until recently, hearing the
                  word “training” made us think of a classroom with an
                  instructor giving a lecture in front of students. And if we
                  were talking about “corporate training,” we would picture a
                  large conference or a day-long workshop.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-0.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="mb-20 about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-1.webp"
                      alt="LMS"
                      className="w-75"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Sofware</small>
                      <h2 className="mb-30">
                        <span>Student Information System</span>
                      </h2>
                    </div>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Management of Subjects</h6>
                      </li>
                      <li className="d-flex align-items-center ">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Admission Management</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt="LMS"
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">LMS Software</small>
                      <h2 className="mb-30">
                        The Solution Best <span>Suited To Your Needs</span>
                      </h2>
                    </div>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Online Application Registration System (OARS)
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Offline Application Processing
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Entrance Test Processing</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Admission Counseling</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Various Statistical Reports</h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-2.webp"
                      alt="LMS"
                      className="w-75"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="LMS"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-3.webp"
                      alt="LMS"
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">LMS Software</small>
                      <h2 className="mb-30">
                        <span>Human Resource System</span>{" "}
                      </h2>
                    </div>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Recruitment Management</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Employee (Personal) Information System
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Leave Management & Time Office
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Employee Appraisal System</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">LMS Software</small>
                      <h2 className="mb-30">
                        <span> Space & Infrastructure Management System</span>
                      </h2>
                    </div>
                    <ul>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Space Hierarchy Definition</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Space & Location Derivations
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">Space Allocation Management</h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          Transport & Fleet Management
                        </h6>
                      </li>
                      <li className="mb-20 d-flex align-items-center">
                        <ArrowRightIcon />
                        <h6 className="fw-bold">
                          General Maintenance Requisition Tracking System
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-4.webp"
                      alt="LMS"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="LMS"
              className="bubble2"
            />
          </div>
          <div className="content sec-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-5.webp"
                      alt="LMS"
                    />
                  </div>
                </div>
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">LMS Software</small>
                      <h2 className="mb-30">
                        <span>Administration Module</span>
                      </h2>
                    </div>
                    <Row>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Institution</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Department</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">University Course</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Subject Allocation</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Time Table</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Examination Process</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Academics Management</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Student Attendance & Analysis
                            </h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Student Marks & Analysis
                            </h6>
                          </li>
                        </ul>
                      </Col>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Subject Type Definition</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Conduct of Examinations</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold"> Secrecy</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Paper Setting</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Fees Module</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Subject</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Students</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Staff</h6>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="LMS"
              className="bubble2"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">LMS Software</small>
                      <h2 className="mb-30">
                        <span>Administration Module</span>
                      </h2>
                    </div>
                    <Row>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Security Management</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Evaluation Definition</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Grade Definition</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Stem Chart</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Moderation</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Issue of Results</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Alumni Management</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Grade Report to Parents</h6>
                          </li>
                        </ul>
                      </Col>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Statistical Reports to Management
                            </h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Evaluation and Results</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              {" "}
                              Finalized Internal Assessment
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              External & Internal Marks
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Award of Degree Management
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Award List</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Re-valuation</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Publish Result</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Re-Exam</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">MIS Reports</h6>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-6.webp"
                      alt="LMS"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="LMS"
              className="bubble2"
            />
          </div>
          <div className="content sec-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-7.webp"
                      alt="LMS"
                    />
                  </div>
                </div>
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">LMS Software</small>
                      <h2 className="mb-30">
                        <span>Academics Module</span>
                      </h2>
                    </div>
                    <Row>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Conduct courses online</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Online student tracking</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Exam Scheduling</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Syllabus</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Self Assessment Tools</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Attendance</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Offline & Online</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Offline Messaging</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Instant Messaging</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Online Lecture Notes</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Upload Lecture Notes</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">User websites</h6>
                          </li>
                        </ul>
                      </Col>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Generation of Question Paper
                            </h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Online Examination</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Setup Question Bank</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Article Management</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Discussion Forum</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Online assignment submission and evaluation
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Logging the access of the lecture materials/notes
                              by students
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Practice Exam and Actual Exams
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Solution Display at the end of the exam (Optional)
                            </h6>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="LMS"
              className="bubble2"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">LMS Software</small>
                      <h2 className="mb-30">
                        <span>Revenue Management Module</span>
                      </h2>
                    </div>
                    <Row>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Academic Fee Collection</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Hostel Fee Collection</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Reports</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Cost Center Management</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Budgeting (Cost Centre based) l Creditors System
                            </h6>
                          </li>
                        </ul>
                      </Col>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Cash Bank</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Purchase Management</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Assets Management</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Inventory & Stores Management
                            </h6>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-8.webp"
                      alt="LMS"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="LMS"
              className="bubble2"
            />
          </div>
          <div className="content sec-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-9.webp"
                      alt="LMS"
                    />
                  </div>
                </div>
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4 ">
                      <small className="title_small">LMS Software</small>
                      <h2 className="mb-30 ">
                        <span>Payroll System</span>
                      </h2>
                    </div>
                    <Row>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Earnings & Deduction Definitions
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Allowance Definitions & Masters
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Earnings, Deduction & Allowance
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Deductions and Allowances
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Leave Management System</h6>
                          </li>
                        </ul>
                      </Col>
                      <Col>
                        <ul>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Time Office Management</h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Shifts Definition and Scheduling
                            </h6>
                          </li>
                          <li className="mb-20 d-flex">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Overtime & Pay-By-Claim processing l Salary
                              Definition & Scheduling
                            </h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">Income Tax Calculation</h6>
                          </li>
                          <li className="mb-20 d-flex align-items-center">
                            <ArrowRightIcon />
                            <h6 className="fw-bold">
                              Pay-Slips generation and mailing
                            </h6>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="LMS"
              className="bubble2"
            />
          </div>
        </section>
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">LMS</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>
                Here are given some our projects to know about our working
                technologies.
              </p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className=" col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad !shadow-xl !shadow-gray-400">
                        <div className="img">
                          <LazyLoadImage
                            className=""
                            src={card.image}
                            alt="LMS"
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href="#">{card.title}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.title}
                          </small>
                          <div
                            className="overflow-hidden text text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                            }}
                          >
                            {card.content}
                          </div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {/* {card.tags} */}
                            </a>
                            <a href="#" className="me-1">
                              {/* {card.tags1} */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>

      <LearningmanagementsystemFAQ />
    </div>
  );
};

export default LMS;
