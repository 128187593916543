import React from "react";
import {FloatingWhatsApp} from "react-floating-whatsapp";
import logo from "./assets/logo-2.png";

export default function Whatsapp() {
  return (
    <FloatingWhatsApp
      phoneNumber="+919266667999"
      accountName="Aara Technologies Pvt Ltd."
      avatar="https://www.shutterstock.com/image-vector/smiling-young-man-headphones-microphone-600w-2213661961.jpg"
      statusMessage="Typically replies within few minutes."
      chatMessage="Hi Thanks if you want to chat with us, please click on below link aaratechnologies.com"
      onClick={"https://aaratechnologies.com/"}
      placeholder="Type a message.."
      chatboxHeight="400px"
    />
  );
}
