import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Sharedbutton from "../Sharelink";
import { baseUrl } from "../../Config/baseUrl";
import toast from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IoEye } from "react-icons/io5";
import moment from "moment/moment";
const Blog = (props) => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [results, setResults] = useState([]);
  const handleSearch = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/blog/search-blog-list?q=`,
        { search_keyword: query },
        {
          headers: {
            token: "6030357fc8126488bf023e910c0dbe35026608c4",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setResults(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const blogData = () => {
    axios
      .post(
        `${baseUrl}/blog/store-based-blog-list-api/`,
        { store_id: 1, category_id: categoryId },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            token: "6030357fc8126488bf023e910c0dbe35026608c4",
          },
        }
      )
      .then((res) => {
        setData(res.data?.blog_list);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err.response?.data?.message);
      });
  };
  const CategoryData = () => {
    const reqBody = new FormData();
    reqBody.append("store_id", 1);
    axios
      .get(
        `${baseUrl}/blog/category-list`,

        {
          params: { store_id: 1 },
          headers: {
            Authorization: "Token 6030357fc8126488bf023e910c0dbe35026608c4",
            // Token: "6030357fc8126488bf023e910c0dbe35026608c4",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setCategoryData(res.data?.category_list);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err.response?.data?.message);
      });
  };
  useEffect(() => {
    blogData();
  }, [categoryId]);
  useEffect(() => {
    CategoryData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const handleCategoryClick = (id) => {
    const element = document.getElementById("category-list");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const navigate = useNavigate();

  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
      </Helmet>
      <h1 className="my-4 text-center">
        Our <span className="bg-white text-primary">Journal</span>
      </h1>
      <p className="text-center">
        Get the latest articles from our journal, writing, discuss and share
      </p>

      <h2 className="mt-5 text-center">POPULAR POSTS</h2>
      <section className="mt-5 portfolio style-1">
        <div className="content">
          <div className=" row mix-container">
            {data.slice(0, 3)?.map((card) => (
              <div
                className="transition-all duration-500 ease-in-out transform scale-90 border shadow-2xl  col-lg-4 rounded-2xl mix security consultation hover:scale-75 !bg-none hover:shadow-purple-500"
                onClick={() => navigate(`/blog/${card?.slug}`)}
              >
                <div class="portfolio-card mb-30">
                  <div class="img">
                    <LazyLoadImage
                      src={card.images}
                      alt="Blog"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  <div class="info">
                    <h5>
                      <a href={card.slug}>{card.title}</a>
                    </h5>
                    <small class="d-block color-main text-uppercase">
                      {card.publish_date}
                    </small>
                    <div class="text">{card.sub_title}</div>
                    <div class="tags flex justify-between">
                      <a href="#" class="me-1">
                        {card.category}
                      </a>
                      <a href="#" class="me-1">
                        {card.sub_category}
                      </a>

                      <div className="flex items-center justify-end w-full gap-2 pr-5 font-semibold ">
                        <div>
                          {moment(card?.date_created).format("DD-MM-YYYY")}
                        </div>
                        <IoEye className="text-xl " />
                        {card?.view_count}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <hr />

      <Row>
        <Col lg="8">
          <div className="row mix-container " id="category-list">
            {data.slice(0, 7)?.map((card) => (
              <div className="">
                <div
                  className="portfolio-card madcaf"
                  onClick={() => {
                    navigate(`/blog/${card?.slug}`);
                  }}
                  style={{ objectFit: "contain" }}
                >
                  <div className="img madwi">
                    <LazyLoadImage
                      src={card.images}
                      alt="Blog"
                      style={{ borderRadius: "20px", objectFit: "contain" }}
                    />
                  </div>
                  <div className="info">
                    <h5>
                      <a>{card.title.slice(0, 50)}</a>
                    </h5>
                    <div className="section-head style-4 mt-30">
                      <small className="title_small">{card.category}</small>
                      &nbsp;
                      <small className="title_small">{card.sub_category}</small>
                    </div>
                    <div className="mb-20 text">{card.sub_title}</div>
                    <div class="auther-comments d-flex small align-items-center justify-content-between op-9">
                      <div class="l_side d-flex align-items-center ">
                        <AccountCircleIcon fontSize="6" />
                        <a href="#">
                          <small class="text-muted">By</small> {card.author}
                        </a>
                      </div>
                      <div class="r-side mt-1">
                        <MessageIcon fontSize="5" />
                        <a href="#">&nbsp; 23</a>
                        <VisibilityIcon fontSize="5" />
                        <a href="#">&nbsp;774k</a>
                      </div>
                    </div>
                  </div>
                </div>
                <Sharedbutton
                  url={`${window.location.href}/${card.id}/${card?.title}`}
                  className="my-2 w-25"
                />
                <hr />
              </div>
            ))}
          </div>
          {/* <div class="pagination style-5 color-4 justify-content-center mt-60">
          {data?.map((card) => (
            <a class="active" onClick={()=>navigate(`/blog/${card?.id}/${card?.slug}`)}>
              <span>{card.id}</span>
            </a>
          
          ))}
          </div> */}
        </Col>
        <Col lg="4" className="mt-5 style-5 ps-lg-5 mt-lg-0">
          <h5 className="mb-20">Search</h5>
          <div className="mb-40 form-group position-relative d-flex">
            <input
              type="text"
              className="form-control rounded-pill"
              placeholder="Type and hit enter"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button
              className="bg-transparent border-0 search-btn1"
              onClick={handleSearch}
            >
              <SearchIcon />
            </button>
          </div>
          <h6 className="mb-20">CATEGORIES</h6>
          <div className="row mix-container mb-50">
            {categoryData?.map((card) => (
              <div>
                <div
                  className="portfolio-card madcaf1 !py-0 !my-0 flex items-center"
                  // onClick={() => {
                  //   navigate("#category-list");
                  //   // navigate(`/blog/${card?.id}/${card?.title}`);
                  // }}
                  onClick={() => {
                    handleCategoryClick(card.id);
                    setCategoryId(card.id);
                  }}
                >
                  <div className="img madwi1 !py-0 w-[30%] ">
                    <LazyLoadImage
                      src={card.images}
                      alt="Blog"
                      style={{
                        borderRadius: "20px",
                        width: "70%",
                      }}
                      className="!shadow-lg !bottom-3"
                    />
                  </div>
                  <div className="info1 w-[70%]">
                    <h5 className="!text-lg">{card?.name}</h5>
                    {/* <div className="text-base font-medium text text1">
                      {card?.name}
                    </div> */}
                  </div>
                </div>
                <hr className="!my-2" />
              </div>
            ))}
          </div>
          <h6 className="mb-20">RECENT POST</h6>
          <div className="row mix-container mb-50">
            {data?.slice(0, 8).map((card) => (
              <div>
                <div
                  className="portfolio-card madcaf1 "
                  onClick={() => navigate(`/blog/${card?.slug}`)}
                >
                  <div className="img madwi1 !w-[40%] ">
                    <LazyLoadImage
                      src={card.images}
                      alt="Blog"
                      style={{
                        borderRadius: "20px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="info1 w-[60%]">
                    <h5>
                      <a href={card.link}>{card.title.slice(0, 30)}</a>
                    </h5>
                    <div className="text text1">
                      {card.sub_title.slice(0, 50)}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>

          <Card style={{ backgroundColor: "#eaeef2" }} className="mb-50">
            <Card.Body>
              <Card.Title>NEWSLETTER</Card.Title>
              <Card.Text>
                Register now to get latest updates on promotions & coupons.
              </Card.Text>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>
                <Button type="submit" className="madbu">
                  Subscribe
                </Button>
              </Form>
            </Card.Body>
          </Card>

          <div class="side-share mb-50 ">
            <h6 class="title mb-20 text-uppercase fw-normal">social</h6>
            <Sharedbutton />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Blog;
