import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Sharedbutton from "../Sharelink";
import { baseUrl } from "../../Config/baseUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
const News = (props) => {
  const [data, setData] = useState([]);

  const blogData = () => {
    axios
      .post(
        `${baseUrl}/blog/store-based-news-list-api/`,
        { store_id: 1 },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data?.news_details);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      });
  };
  useEffect(() => {
    blogData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const navigate = useNavigate();
  return (
    <div
      className="container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={data?.slug} />
        <meta property="og:title" content={data?.Title} />
        <meta property="og:description" content={data?.meta_description} />
        <meta property="og:image" content={data?.images} />
      </Helmet>
      <h1 className="my-4 text-center">
        Latest <span className="bg-white text-primary">News</span>
      </h1>
      <p className="text-center">
        Get the latest News from our work, writing, discuss and share
      </p>

      <h2 className="mt-5 text-center">POPULAR NEWS</h2>
      <section className="mt-5 portfolio style-1">
        <div className="content">
          <div className="row mix-container">
            {data?.slice(0, 3)?.map((card) => (
              <div
                className="col-lg-4 mix security consultation"
                onClick={() => navigate(`/news/${card?.id}/${card?.slug}`)}
              >
                <div class="portfolio-card border mb-30">
                  <div class="img">
                    <LazyLoadImage
                      src={card.images}
                      alt=""
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  <div class="info">
                    <h5>
                      <a href={card.slug}>{card.Title}</a>
                    </h5>
                    <small class="d-block color-main text-uppercase">
                      {card.created_at}
                    </small>
                    <div class="text">{card.meta_description}</div>
                    <div class="tags">
                      <a href="#" class="me-1">
                        {card.keyword}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <hr />

      <div className="row mix-container">
        {data?.slice(0, 7)?.map((card) => (
          <div className="">
            <div
              className="portfolio-card madcaf"
              onClick={() => navigate(`/news/${card?.id}/${card?.slug}`)}
              style={{ objectFit: "contain" }}
            >
              <div className="img2 madwi">
                <LazyLoadImage
                  src={card.images}
                  alt=""
                  style={{
                    borderRadius: "20px",
                    objectFit: "contain",
                    maxWidth: "60%",
                    maxHeight: "40%",
                  }}
                />
              </div>
              <div className="info">
                <h5 className="display-6">
                  <a>{card?.Title?.slice(0, 50)}</a>
                </h5>
                <div className=" mt-30">
                  <p className="">{card.meta_description}</p>
                  &nbsp;
                  <small className="title_small">{card.sub_category}</small>
                </div>
                <div className="mb-20 text">{card.sub_title}</div>
                <div class="auther-comments d-flex small align-items-center justify-content-between op-9">
                  <div class="l_side d-flex align-items-center ">
                    <AccountCircleIcon fontSize="6" />
                    <a href="#">
                      <small class="text-muted">By</small> {card.author}
                    </a>
                  </div>
                  <div class="r-side mt-1">
                    <MessageIcon fontSize="5" />
                    <a href="#">&nbsp; 23</a>
                    <VisibilityIcon fontSize="5" />
                    <a href="#">&nbsp;774k</a>
                  </div>
                </div>
              </div>
            </div>
            <Sharedbutton
              url={`${window.location.href}/${card.id}/${card.slug}`}
              className="my-2 w-25"
            />
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
