var dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

export default function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-310897006/G-I1COmHk-kYEO7Sn5QB",
    // event_callback: callback,
  });
  return false;
}
