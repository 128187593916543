import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import usePageView from "../usePageView";

const Layout = ({ component }, props) => {
  usePageView();
  return (
    <>
      <Header />
      <div className="pt6">{component}</div>
      <Footer />
    </>
  );
};

export default Layout;
