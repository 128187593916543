import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/AboutUs";
import Mobileappdevelopment from "./Pages/Services/Mobileappdevelopment";
import Ecommercewithcms from "./Pages/Services/ecommercewithcms";
import Websitedesignanddevelopment from "./Pages/Services/Websitedesignanddevelopment";
import Cloudbasedsoftware from "./Pages/Services/Cloudbasedsoftware";
import Deliveryapp from "./Pages/Services/Deliveryapp";
import APIdevelopment from "./Pages/Services/Apidevelopment";
import Customcrmdevelopment from "./Pages/Services/Customcrmdevelopment";
import Customhrmdevelopment from "./Pages/Services/Customhrmdevelopment";
import Automatedremindersystem from "./Pages/Services/Automatedremindersystem";
import Mlm from "./Pages/Services/Mlm";
import Ecommerceappdevelopment from "./Pages/Services/Ecommerceappdevelopment";
import Enterprisesoftwaresolution from "./Pages/Services/Enterprisesoftwaresolution";
import Softwaredevelopment from "./Pages/Services/Software development";
import Amcforwebsiteandmobileapp from "./Pages/Services/Amcforwebsiteandmobileapp";
import Ecommercemarketplacedevelopment from "./Pages/Services/Ecommercemarketplacedevelopment";
import Customerpdevelopment from "./Pages/Services/Customerpdevelopment";
import Customsoftwaredevelopment from "./Pages/Services/Customsoftwaredevelopment";
import Learningmanagementsystem from "./Pages/Services/Learningmanagementsystem";
import Uiuxdesign from "./Pages/Services/Uiuxdesign";
import Magentodevelopment from "./Pages/Technologies/Magentodevelopment";
import Woocommercedevelopment from "./Pages/Technologies/Woocommercedevelopment";
import Prestashopdevelopment from "./Pages/Technologies/Prestashopdevelopment";
import Odoodevelopment from "./Pages/Technologies/Odoodevelopment";
import Laraveldevelopment from "./Pages/Technologies/Laraveldevelopment";
import Iosappdevelopment from "./Pages/Technologies/Iosappdevelopment";
import Flutterdevelopment from "./Pages/Technologies/Flutterdevelopment";
import Iotappdevelopment from "./Pages/Technologies/Iotappdevelopment";
import Reactnativedevelopment from "./Pages/Technologies/Reactnativedevelopment";
import Shopifydevelopment from "./Pages/Technologies/Shopifydevelopment";
import Cscartdevelopment from "./Pages/Technologies/Cscartdevelopment";
import Codeigniterdevelopment from "./Pages/Technologies/Codeigniterdevelopment";
import Pythondevelopment from "./Pages/Technologies/Pythondevelopment";
import Aiarproduct from "./Pages/Technologies/Aiarproduct";
import Angulardevelopment from "./Pages/Technologies/Angulardevelopment";
import Nodejsdevelopment from "./Pages/Technologies/Nodejsdevelopment";
import Androidappdevelopment from "./Pages/Technologies/Androidappdevelopment";
import Opencartdevelopment from "./Pages/Technologies/Opencartdevelopment";
import Layout from "./Layout";
import ContactUs from "./Pages/ContactUs";
import Project from "./Pages/Project";
import Blog from "./Pages/Blog";
import Job from "./Pages/Jobopening";
import Privacy from "./Pages/Privacypolicy";
import Page from "./Pages/Blog/Page";
import Page404 from "./Pages/pagenotfound";
import Signin from "./Authentication/SignIn";
import News from "./Pages/News";
import Detailnews from "./Pages/News/Detailnews";
import ConsentBanner from "./ConsentBanner";
import Customer from "./Pages/Services/our-cousmoter";
import About1 from "./Pages/Services/our-cousmoter";
import TryCode from "./try";
import ProjectDetails from "./Pages/Project/projectdetails";

function App() {
  return (
    <>
      <BrowserRouter>
        <ConsentBanner />
        <Routes>
          <Route path="/signin" element={<Layout component={<Signin />} />} />
          <Route
            path="/"
            element={
              <Layout
                component={
                  <Home
                  /* title="Software & Mobile Apps Development Services | Aara Technologies"
                   description="Aara Technologies is Trusted and leading software and mobile app developement company in Noida, India. We have team of expert developers to build Software, CRM, ERP, AI, Digital Transformation, Cloud Operation & mobile apps."
                   keywords="Best Website Development Company in Lucknow, Best Mobile App Development Company in Lucknow, Software Development Company in Lucknow, Software Development company in NCR, best Software Development Company in Noida, E-commerce website development company in Lucknow." */
                  />
                }
              />
            }
          />
          <Route
            path="/about"
            element={
              <Layout
                component={
                  <About
                  /*  title="About The Leading Software Company - Aara Technologies"
                    description="Aara Technologies - Best website design & development company specializing in responsive and parallax website designing in Lucknow (India), Africa & Europe."
                    keywords="Leading Software Company In India" */
                  />
                }
              />
            }
          />
          <Route
            path="/contact"
            element={
              <Layout
                component={
                  <ContactUs
                  /* title="Contact Us | Best Software Development Company - Aara Technologies"
                   description="Contact Us right away to discuss your software application development projects with the best leading software company in India."
                   keywords="Best Software Development Company, Website Designing & Development" */
                  />
                }
              />
            }
          />
          <Route
            path="/ourproject"
            element={
              <Layout
                component={
                  <Project
                  /*  title="Projects and Portfolio of Aara technologies"
                    description="Check the latest project and portfolios competed By Aara Technologies. We have successfully completed more than 650 projects so far now."
                    keywords="Best Website Development Company in Lucknow, Best Mobile App Development Company in Lucknow, Software Development Company in Lucknow, Software Development company in NCR, best Software Development Company in Noida, E-commerce website development company in Lucknow." */
                  />
                }
              />
            }
          />
          <Route
            path="/ourproject/:id"
            element={
              <Layout
                component={
                  <ProjectDetails
                  /* title="Projects and Portfolio of Aara technologies"
                   description="Check the latest project and portfolios competed By Aara Technologies. We have successfully completed more than 650 projects so far now."
                   keywords="Best Website Development Company in Lucknow, Best Mobile App Development Company in Lucknow, Software Development Company in Lucknow, Software Development company in NCR, best Software Development Company in Noida, E-commerce website development company in Lucknow." */
                  />
                }
              />
            }
          />
          <Route
            path="/mobile-app-development"
            element={
              <Layout
                component={
                  <Mobileappdevelopment
                  /* title="Mobile App Development Company For Android & IOS"
                   h1="Mobile App Development Company."
                   description="Do you find a mobile app development company for IOS? Aara Technologies offers innovative solutions to bring your app ideas to life. Contact us today!"
                   keywords="Mobile app development company in delhi,top mobile app development company,top mobile app development company in india,mobile app development company in noida,best mobile app development company." */

                  />

                }
              />

            }
          />
          <Route
            path="/e-commerce-app-with-cms"
            element={
              <Layout
                component={
                  <Ecommercewithcms
                  /* title="Professional CMS Development Services In India  |CMS Development Services"
                   h1="eCommerce App with CMS."
                   description="Get Expert CMS development services for efficient content management solutions. We help to maintain, and manage your online content."
                   keywords="Cms mobile app,headless cms mobile app,cms mobile app development company,cms mobile app development company in noida,cms mobile app development company in india." */
                  />
                }
              />
            }
          />
          <Route
            path="/website-design-and-development"
            element={
              <Layout
                component={
                  <Websitedesignanddevelopment
                  /*title="Top Web design and Development Services | Aara Technologies| Best Website Design & Development Company."
                  description="Elevate your digital presence with Aara Technologies's expert website design and development services. We create stunning, user-friendly websites that captivate your audience."
                  keywords="Website development company in india,website development company india,website development company in delhi,website development company in noida,best website development company in india,website development company in lucknow,website design & development,website design & development services,website design & development compan." */
                  />
                }
              />
            }
          />
          <Route
            path="/cloud-based-open-source-software-development"
            element={
              <Layout
                component={
                  <Cloudbasedsoftware
                  /* title="Best Cloud Based Software Development Company- Aara Technologies | Customized Cloud Based Open Source Software Development"
                   h1="Customized Cloud Based Open Source Software Development."
                   description="Transform your business with cutting-edge cloud-based software solutions. Our experienced team delivers scalable, secure, and innovative applications, leveraging the full potential of cloud technologies. "
                   keywords="cloud based software company,cloud based software services,cloud based software development,cloud-based application development,cloud-based application development in india,cloud-based application development in noida." */
                  />
                }
              />
            }
          />
          <Route
            path="/delivery-app"
            element={
              <Layout
                component={
                  <Deliveryapp
                  /*title="Best Delivery App Development Company - Aara Technologies"
                  description="Are you looking for a Delivery App? Aara Technologies is the top rated Delivery App Development company that offers quality Delivery App Development solutions."
                  keywords="Best Delivery App Development Company, Delivery App Development Company, Best Delivery App Development."*/
                  />
                }
              />
            }
          />
          <Route
            path="/api-development"
            element={
              <Layout
                component={
                  <APIdevelopment
                  /*title="API development Services for CMS, ERP CRM, Mobile App"
                  h1="API Development company."
                  description="Get custom API development services in India for e-commerce, marketplaces, mobile apps, POS, headless microservices, CMS, ERP, and CRM. Call us now."
                  keywords="Api development,api development company,custom api development company,API development company in india,API development company in noida,api development services,api development service provider." */
                  />
                }
              />
            }
          />
          <Route
            path="/crm-development"
            element={
              <Layout
                component={
                  <Customcrmdevelopment
                  /* title="CRM Development Company In India | Aara Technologies"
                   description="Get to enhance your business efficiency with CRM solutions. We are dedicated to offering the Best CRM Development services in India. Contact us today."
                   keywords="Best crm development company, top crm development company, crm development company, custom crm software company, crm software design company, best crm for small business, crm software at a budget price, best cloud crm service seller, crm software company near me, crm integration services, affordable crm design company, best crm development company, customized crm development, crm software development firm, powerful sales crm platform, India's Best Sales crm company, custom crm development company ." */
                  />
                }
              />
            }
          />
          <Route
            path="/hrm-development"
            element={
              <Layout
                component={
                  <Customhrmdevelopment
                  /*title="HRM Development Company In India | Custom HR Process"
                  h1="Custmoized HRM Software Development."
                  description="Unlock the full potential of your HR operations with Aara Technologies, the industry's top HRM software development company."
                  keywords="Aara Technologies is a leading HRM development company. We offer a range of services that streamline your HR process with custom solutions for efficient employee management." */
                  />
                }
              />
            }
          />
          <Route
            path="/automated-reminder-system"
            element={
              <Layout
                component={
                  <Automatedremindersystem
                  /*title="Best Automated Reminder System Development Company - Automated Reminder System"
                  h1="Automated Reminder System."
                  description="Revolutionizing Productivity with Best-in-Class Reminder Software Development with Aara Technologies."
                  keywords="Reminder software,Reminder Software development company,online reminder software,Appointment Reminder software." */
                  />
                }
              />
            }
          />
          <Route
            path="/mlm"
            element={
              <Layout
                component={
                  <Mlm
                  /* title="Mlm Software Development Company | Multilevel Marketing Software Development"
                   h1="Mlm Software Development Company."
                   description="Aaratechnologies: A trusted name in MLM software development, renowned for crafting exceptional solutions that empower businesses to thrive and dominate the network marketing industry."
                   keywords="Mlm software company,mlm software company in india,mlm software company in delhi,MLM software development company in Noida,mlm software company in noida,mlm software development company,mlm software development company in delhi,mlm software company in lucknow,mlm software,mlm service provider, mlm service provider noida, mlm service provider in noida, multi level marketing." */
                  />
                }
              />
            }
          />
          <Route
            path="/ecommerce-app-development"
            element={
              <Layout
                component={
                  <Ecommerceappdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/software-development"
            element={
              <Layout
                component={
                  <Softwaredevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/enterprise-Software-solutions"
            element={
              <Layout
                component={
                  <Enterprisesoftwaresolution
                  />
                }
              />
            }
          />
          <Route
            path="/amc-for-website-and-mobile-app"
            element={
              <Layout
                component={
                  <Amcforwebsiteandmobileapp
                  />
                }
              />
            }
          />
          <Route
            path="/e-commerce-web-development"
            element={
              <Layout
                component={
                  <Ecommercemarketplacedevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/erp-development"
            element={
              <Layout
                component={
                  <Customerpdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/customized-software-development"
            element={
              <Layout
                component={
                  <Customsoftwaredevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/lms"
            element={
              <Layout
                component={
                  <Learningmanagementsystem
                  />
                }
              />
            }
          />
          <Route
            path="/ui-ux-design"
            element={
              <Layout
                component={
                  <Uiuxdesign
                  />
                }
              />
            }
          />

          <Route
            path="/magento-development"
            element={
              <Layout
                component={
                  <Magentodevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/woocommerce-development"
            element={
              <Layout
                component={
                  <Woocommercedevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/prestashop-development"
            element={
              <Layout
                component={
                  <Prestashopdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/odoo-development"
            element={
              <Layout
                component={
                  <Odoodevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/laravel-development"
            element={
              <Layout
                component={
                  <Laraveldevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/ios-app-development"
            element={
              <Layout
                component={
                  <Iosappdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/flutter-development"
            element={
              <Layout
                component={
                  <Flutterdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/iot-development"
            element={
              <Layout
                component={
                  <Iotappdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/react-native-development"
            element={
              <Layout
                component={
                  <Reactnativedevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/opencart-development"
            element={
              <Layout
                component={
                  <Opencartdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/shopify-development"
            element={
              <Layout
                component={
                  <Shopifydevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/cs-cart-development"
            element={
              <Layout
                component={
                  <Cscartdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/codeigniter-development"
            element={
              <Layout
                component={
                  <Codeigniterdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/python-development"
            element={
              <Layout
                component={
                  <Pythondevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/android-app-development"
            element={
              <Layout
                component={
                  <Androidappdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/ai-ar-product"
            element={
              <Layout
                component={
                  <Aiarproduct
                  />
                }
              />
            }
          />
          <Route
            path="/angular-development"
            element={
              <Layout
                component={
                  <Angulardevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/nodejs-development"
            element={
              <Layout
                component={
                  <Nodejsdevelopment
                  />
                }
              />
            }
          />
          <Route
            path="/blog"
            element={
              <Layout
                component={
                  <Blog


                  />
                }
              />
            }
          />
          <Route
            path="/blog/:slug"
            element={<Layout component={<Page />} />}
          />
          <Route
            path="/job-opening"
            element={
              <Layout
                component={
                  <Job
                  />
                }
              />
            }
          />
          <Route
            path="/news/:id/:slug"
            element={<Layout component={<Detailnews />} />}
          />
          <Route
            path="/news"
            element={
              <Layout
                component={
                  <News
                  />
                }
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Layout
                component={
                  <Privacy
                  /*title="privacy - Aara Technologies"
                  description="Aara Technologies - Best website design & development company specializing in responsive and parallax website designing in Lucknow (India), Africa & Europe."
                  keywords="Leading Software Company In India."*/
                  />
                }
              />
            }
          />
          <Route
            path="*"
            element={
              <Layout
                component={
                  <Page404
                    title="404 page - Aara Technologies"
                    description="This page is not found"
                    keywords="This page is not found."
                  />
                }
              />
            }
          />



          <Route
            path="/our-customer"
            element={
              <Layout
                component={
                  <Customer
                  /* title="CRM Development Company In India | Aara Technologies"
                   description="Get to enhance your business efficiency with CRM solutions. We are dedicated to offering the Best CRM Development services in India. Contact us today."
                   keywords="Best crm development company, top crm development company, crm development company, custom crm software company, crm software design company, best crm for small business, crm software at a budget price, best cloud crm service seller, crm software company near me, crm integration services, affordable crm design company, best crm development company, customized crm development, crm software development firm, powerful sales crm platform, India's Best Sales crm company, custom crm development company ." */
                  />
                }
              />
            }
          />
          <Route
            path="/about1"
            element={
              <Layout
                component={
                  <About1
                  /* title="CRM Development Company In India | Aara Technologies"
                   description="Get to enhance your business efficiency with CRM solutions. We are dedicated to offering the Best CRM Development services in India. Contact us today."
                   keywords="Best crm development company, top crm development company, crm development company, custom crm software company, crm software design company, best crm for small business, crm software at a budget price, best cloud crm service seller, crm software company near me, crm integration services, affordable crm design company, best crm development company, customized crm development, crm software development firm, powerful sales crm platform, India's Best Sales crm company, custom crm development company ." */
                  />
                }
              />
            }
          />
          <Route
            path="/try"
            element={
              <Layout
                component={
                  <TryCode

                  />
                }
              />
            }
          />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
