import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomhrmdevelopmentFAQ from "../../../FAQPages/ServicesFAQ/Customhrmdevelopment";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";
const Project = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Product Inventory mangement Software",
      type1: "Software development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-inventory-management.jpg",
      link: "",
      description:
        "Product Inventory management Software is the part of cloud based erp software. ",
      tags: "Cloud based software",
    },
    {
      name: "Product human resource management App",
      type1: "Software DEVELOPMENT",
      description:
        "Product human resource management App is the part of cloud based hrm software.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-human-resource-management.jpg",
      link: "",
      tags: "Cloud based software",
    },
    {
      name: "Product multi level marketing software App",
      type1: "Software DEVELOPEMNT",
      description:
        "Product multi level marketing App is the part of cloud based mlm software",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-multi-level-marketing.jpg",
      link: "",
      tags: "Cloud based software",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${31}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">HRM Software</small>
              <h2 className="mb-20">
                <span>HR Software Development</span>
              </h2>
              <p>
                We are providing best Custmoized HR Software Development
                service.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Software</small>
                <h2 className="mb-20">
                  Custmoized <span> HRM Software Development </span>
                </h2>
                <p>
                  Human Resource Management (HRM) Development is the framework
                  for helping employees develop their personal and
                  organizational skills, knowledge, and abilities. Human
                  Resource Management System Development can be formal such as
                  in-classroom training, a college course, or an organizational
                  planned change effort.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/hr-sofware.webp"
                  className="imgmad mt-30"
                  alt="custom3"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-4">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/product-human-resource-management.jpg"
                      alt="custom3"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">HRM</small>
                        <h2>
                          When You Need To Use <span> HRM Software </span>
                        </h2>
                        <br />
                      </div>
                      <p>
                        HRM Software is an automated payroll system that
                        diminishes the need for outsourcing payroll to a
                        third-party provider. The payroll system can be easily
                        linked to employee information, time, and attendance
                        tracking software.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      p
                      <div className="section-head style-4">
                        <small className="title_small">HRM</small>
                        <h2 className="mb-40">
                          <span>Cost Management</span>
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        Labor costs are almost the largest cost item. And HRM
                        systems are used for planning and optimization of costs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                      alt="custom3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="custom3"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-ui-theme.webp"
                      alt="custom3"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">HRM</small>
                        <h2 className="mb-30">
                          Efficient <span>Management Of Business </span>
                          Processes
                        </h2>
                      </div>
                      <p>
                        HRM system solutions make it possible to handle many HR
                        business processes like personnel solutions, keeping
                        records about employees, payrolling, development of
                        motivation schemes, etc. Also, HR managers give
                        employees direct access to the required information, and
                        it increases efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600 "
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">HRM</small>
                        <h2 className="mb-40">
                          <span>Human Capital </span> Improvement
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        Companies need to focus on employee's career and skill
                        growth, developing incentive programs and motivational
                        schemes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/track_states.webp"
                      alt="custom3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="custom3"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-ui-theme.webp"
                      alt="custom3"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">
                          Advantage Of Human Resources Management System
                        </small>
                        <h2 className="mb-30">
                          <span>Information Centralized </span>
                        </h2>
                      </div>
                      <p>
                        This software allows them to user make their profile,
                        where they have assigned tasks and the profile contains
                        all important information about an employee. Employees
                        can update all the information by themselves, and it
                        saves the time of HR managers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2 className="mb-40">
                          <span>Progress Monitoring</span>
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        HR managers know like nobody else that employees should
                        be motivated enough to work efficiently. Otherwise, an
                        employee won’t see a real goal to achieve and why they
                        should work at your company further.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/testing.png"
                      alt="custom3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="custom3"
              className="bubble2"
            />
          </div>
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-4">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Reporting%26BI.webp"
                      alt="custom3"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2>
                          <span> Payroll Handling </span>
                        </h2>
                        <br />
                      </div>
                      <p>
                        Expenses management is one of the top advantages of the
                        HRM system, HR managers along with accounting managers
                        may estimate payrolls, taxes, sick leaves, and
                        vacations. Thus, all financial information will be
                        available only for internal use and no third-party
                        providers will participate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">
                          Features Of HRM Software
                        </small>
                        <h2 className="mb-40">
                          <span>Progress Tracking</span>
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        By using this HR can monitor the performance of all
                        employees and also HR can assign a specific task, Thus
                        HR team keeps all updates.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/automation-2.webp"
                      className="w-75"
                      alt="custom3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="custom3"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/scheduling.jpg"
                      alt="custom3"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">HRM</small>
                        <h2 className="mb-30">
                          <span>Scheduling</span>
                        </h2>
                      </div>
                      <p>
                        HR managers can schedule employee shifts or assign
                        specific shifts to any employee.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2>
                          <span> Time Tracking </span>
                        </h2>
                        <br />
                      </div>
                      <p>
                        When specific tasks are assigned it is very important to
                        monitor how much time it takes for an employee to
                        complete any specific task. Time tracking is a very
                        important part of reviewing specific tasks. Time
                        tracking is also playing an important role to track sick
                        leaves and paid leaves etc.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/time.png"
                      className="w-60"
                      alt="custom3"
                    />
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>

          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/amc.webp"
                      alt="custom3"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2 className="mb-30">
                          <span>Recruiting Tracking </span>
                        </h2>
                      </div>
                      <p>
                        Managers can easily track the applicant records, how
                        many employees are applying for specific jobs, Also this
                        feature helps HR managers work with vacancy
                        announcements, Post them on job platforms, and so on.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2 className="mb-40">
                          <span>Education Management</span>
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        This feature is crucial since the HR department should
                        be interested in the self-development of employees. To
                        retain skilled specialists, it is necessary to provide
                        them with an opportunity to learn something new
                        permanently.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-4.webp"
                      alt="custom3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="custom3"
              className="bubble2"
            />
          </div>
          <div className="content frs-content" id="about">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-4">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/lms-6.webp"
                      alt="custom3"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">HRM</small>
                        <h2>
                          <span> Legal Compliance </span>
                        </h2>
                        <br />
                      </div>
                      <p>
                        It means compliance with legal items that regulate
                        relationships between employee and employer. HRM system
                        allows users to settle complicated issues, and implement
                        flexible payrolling schemes and personnel document flow.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">HRM Software</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here are given some our projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className=" col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad !shadow-xl !shadow-gray-400">
                        <div className="img">
                          <LazyLoadImage
                            className=""
                            src={card.image}
                            alt="custom3"
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href="#">{card.title}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.title}
                          </small>
                          <div
                            className="overflow-hidden text text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                            }}
                          >
                            {card.content}
                          </div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {/* {card.tags} */}
                            </a>
                            <a href="#" className="me-1">
                              {/* {card.tags1} */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <CustomhrmdevelopmentFAQ />
    </div>
  );
};

export default Project;
