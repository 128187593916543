import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Jobopening = (props) => {
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: (values) => {
      const reqBody = {
        email: values.email,
        password: values.password,
      };

      axios
        .post("http://192.168.1.188:8000/blog/blog-store-login/", reqBody)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          res.data.message === "Login Successfully ! " && navigate("/");
          alert(res.data.message);
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    },
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="keywords" content={props.keywords} />
      </Helmet>
      <main className="portfolio-page style-1">
        <section className="portfolio-projects section-padding style-1 bg-white">
          <div className="container"></div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <Accordion className="mb-40">
                    <Accordion.Item>
                      <Accordion.Header>
                        <h2 className="color-blue4">Admin Login</h2>
                      </Accordion.Header>
                      <Accordion.Body>
                        <form
                          onSubmit={formik.handleSubmit}
                          class="form"
                          method="post"
                        >
                          <div class="row">
                            <h3 className="text-center mb-4">
                              Please login Here
                            </h3>

                            <div class="col-lg-12">
                              <div class="form-group mb-20">
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  class="form-control"
                                  placeholder="Email"
                                  value={formik.values["email"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            <div class="col-lg-12">
                              <div class="form-group mb-20">
                                <input
                                  type="password"
                                  name="password"
                                  id="password"
                                  class="form-control"
                                  placeholder="Password"
                                  value={formik.values["password"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>

                            <div class="col-lg-12 text-center">
                              <input
                                type="submit"
                                className="btn rounded-pill blue5-3Dbutn hover-blue2 fw-bold md1"
                                value="Send Your Request"
                              />
                            </div>
                          </div>
                        </form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Jobopening;
