import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LaraveldevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Laraveldevelopment";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";

const Laravel = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Oemspa Web",
      type1: "Web development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/oemspa-web.png",
      tags: "Laravel development",
    },
    {
      name: "rajs sound waves",
      type1: "Web development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rajssoundwaves.png",
      tags: "Laravel development",
    },
    {
      name: "Rajat college",
      type1: "Web development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/Rajatcollege.png",
      tags: "Laravel development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${61}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Web Development</small>
              <h2 className="mb-20">
                <span>Laravel Development</span> Company
              </h2>
              <p>
                We have an experienced team of Laravel Development that's
                provide best solution.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">Web Development</small>
                <h2 className="mb-20">
                  <span>Laravel Development</span> Company
                </h2>
                <p>
                  We are a top-level laraval development company, we are
                  offering laravel development services which include custom
                  development, customization, CRM, eCommerce, marketplace, and
                  API integration.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/technologies-based-banner.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/laravel-business.webp"
                      alt=" LaravelDevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          Are You Looking For The Right Solution For A New
                          Business?
                        </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Laravel is built on a component-based architecture and
                      composer-driven approach. It is suitable for both
                      monolithic and microservices apps. Laravel is compatible
                      with any type of business application from eCommerce to
                      CRM software, CMS to SaaS-based apps. Laravel's core
                      includes a slew of packages inbuilt that make development
                      lighting fast and easy to scale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" LaravelDevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <h2 className="mb-30">
                      Advantages Of <span> Laravel Development Service </span>
                    </h2>
                  </div>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Prestashop-icons/Opensource.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>OpenSource Platform</Card.Subtitle>
                          <Card.Text>
                            Laravel is an open-source PHP framework that
                            develops web applications using the MVC.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Routing-Management.svg"
                            alt=" LaravelDevelopment"
                          />
                          <br />
                          <Card.Subtitle>Routing Management</Card.Subtitle>
                          <Card.Text>
                            Routing is the technique of creating request URLs
                            for web applications. The best part is that the URL
                            is both SEO-friendly and human-readable.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Dedicated-Template-Engine.svg"
                            alt=" LaravelDevelopment"
                          />
                          <Card.Subtitle>
                            Dedicated Template Engine
                          </Card.Subtitle>
                          <Card.Text>
                            Blade is a simple yet powerful template engine
                            included in Laravel. You can also use plain PHP code
                            in this template.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Composer-based-MVC.svg"
                            alt=" LaravelDevelopment"
                          />
                          <Card.Subtitle>Composer based MVC</Card.Subtitle>
                          <Card.Text>
                            Laravel uses MVC structure which provides better
                            documentation, improves performance, and provides
                            much inbuilt functionalities
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Highly-Secure.svg"
                            className="mb-20"
                          />

                          <Card.Subtitle>Highly Secure</Card.Subtitle>
                          <Card.Text>
                            A web application must be deeply shielded before it
                            goes live.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Development-Environment.svg"
                            className="mb-20"
                          />
                          <Card.Subtitle>
                            Load Development Environment
                          </Card.Subtitle>
                          <Card.Text>
                            Laravel is a perfect framework for web application
                            development and custom development.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/ORM.svg"
                            alt=" LaravelDevelopment"
                          />
                          <Card.Subtitle>Eloquent ORM</Card.Subtitle>
                          <Card.Text>
                            Eloquent ORM provides the easiest way to connect to
                            the database. It makes the development process
                            easier and faster by
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/Cache-Handlers.svg"
                            alt=" LaravelDevelopment"
                          />
                          <br />
                          <Card.Subtitle>Supports Cache Handlers</Card.Subtitle>
                          <Card.Text>
                            Laravel comes with built-in cache management. The
                            cache is a technology to store data for the future
                            which makes the application run in a faster
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/PHP-Community-Supported.svg"
                            alt=" LaravelDevelopment"
                          />
                          <Card.Subtitle>PHP Community Supported</Card.Subtitle>
                          <Card.Text>
                            Laravel has a powerful community that brings all
                            people cooperatively to promote and support each
                            other in case of any issue or thread.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" LaravelDevelopment"
              className="bubble2"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Here Are The Services Includes For Laravel Development
                      </small>
                      <h2 className="mb-30">
                        Composite Business <span> Application Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We are constantly developing small and complex business
                      applications for several brands around the world. Our
                      technical expert team provides the glassiest development
                      process with superior quality apps.We have over a
                      decennary experience in the world of eCommerce and
                      software development with POS,cross-functional SaaS, PWA,
                      etc. Whether it is a small application or a complex one,
                      our expert team will always provide a correct solution to
                      our clients within suitable time.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/business.webp"
                      alt=" LaravelDevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" LaravelDevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/woo-mobile.webp"
                      alt=" LaravelDevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        ECommerce Development
                      </small>
                      <h2 className="mb-30">
                        <span>ECommerce And Marketplace </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Laravel is well-known for its endless characteristics. It
                      is highly scalable and secure which makes development
                      simpler, speedier, and boosts performance. Our
                      Laravel-based open-source eCommerce platform is AT-Shop.
                      It provides a wide range of utilities, and full control
                      over the store and even you can create a marketplace as
                      well. The platform allows simple customization and easy
                      use for non-tech wits. With the help of our multi-vendor
                      Program, you can also create a marketplace. An online
                      platform where several vendors can collect together to
                      retail their products in a single eCommerce store.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" LaravelDevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span> Headless And Microservices </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      One of the greatest features of Laravel is that it
                      supports Microservices which is very profitable to create
                      an application. The application is developed in several
                      parts which make implementation simpler. These minor parts
                      are connected with each other via API and create a fully
                      functioning application. Headless CMS along with Laravel
                      JSON API helps you to quickly build apps with a nice
                      structure that can easily run across distinct platforms
                      and operating systems.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/headless-and-microservices.webp"
                      alt=" LaravelDevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" LaravelDevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt=" LaravelDevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Mobile Apps </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Laravel is most popular for mobile app development as it
                      offers predefined libraries that make app development
                      simpler.Laravel is the most familiar PHP framework within
                      Github. And also, the most popular choice for the
                      companies and developers to create an app.This PHP is one
                      of the famous frameworks amongst developers to develop a
                      mobile app as it offers improvement in functionalities and
                      many built-in features as well as security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" LaravelDevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Laravel Services And API Integration </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Laravel is a suitable choice to create an API. We can use
                      API in various sectors, build a PWA to integrate online
                      shopping applications or integrate with CRM, ERP, etc. API
                      can be used in various sectors like customer
                      authentication, performing CRUD operations, etc. Also,
                      with the help of web API, you can integrate your
                      application to provide the default content.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/laravel-api.webp"
                      alt=" LaravelDevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" LaravelDevelopment"
              className="bubble2"
            />
          </div>
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <small className="title_small">Odoo ERP</small>
                    <h2 className="mb-30">
                      <span>Support & Development Team</span> Is Known For Its{" "}
                      <span>Good Service & Experience</span>
                    </h2>
                  </div>
                  <p>
                    Aara Technologies team has got a nice experience in
                    providing the solution to eCommerce marketplace and software
                    development, Aara Groups always provides the best experience
                    to their clients.
                  </p>
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/laravel-banner.webp"
                    alt=" LaravelDevelopment"
                  />
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" LaravelDevelopment"
              className="bubble"
            />
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Web Development</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt=" LaravelDevelopment"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <LaraveldevelopmentFAQ />
    </div>
  );
};

export default Laravel;
