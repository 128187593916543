import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../Config/baseUrl";
import toast from "react-hot-toast";
import axios from "axios";

const About1 = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/blog/change-page-content-api/`);
      setData(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {data.map((item) => (
        <div key={item.id}>
          <p dangerouslySetInnerHTML={{ __html: item.content }} />
          <div>{item.content}</div>
        </div>
      ))}
    </div>
  );
};

export default About1;
