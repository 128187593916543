import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl } from "../../Config/baseUrl";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProjectDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const getProject = () => {
    axios
      .get(`${baseUrl}/blog/project-image-api/?project_id=${id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res?.data?.data);
      });
  };
  useEffect(() => {
    getProject();
  }, []);
  return (
    // <div className="container flex flex-col p-4 bg-white lg:justify-center">
    //     <div className='py-3 text-4xl font-medium' >Chai Point</div>
    //     <img src='https://www.carmatec.com/wp-content/uploads/2023/02/desk-screen-chaipoint.jpg.webp' alt='Error' />
    //     <div className='py-3 text-3xl font-bold' >Project Description</div>
    //     <div className='space-y-5'  >
    //         <span className='block text-base font-thin'>Chai Point is an Indian tea company and a cafe chain which focuses on tea-based beverages where users can view & order the Chai Point products and make an online purchase from the nearest store. The vision of this project is to re-design and develop a ReactJS E-Commerce website Front-End (order & shop) for the brand Chai Point. The project includes a website Front-end revamp for the brand, a consolidation of the Chai Point brands in the long run for the end-users, and with the help of the website, the users can view and order the Chai Point products and make an online purchase from the nearest store.</span>

    //         <span className='block text-base font-thin'>The main goal of this project is to provide a seamless online experience for Chai Point’s customers, showcasing their brand story and products in an easy-to-use interface. The website will feature both web-based ordering and WhatsApp ordering capabilities, providing a convenient and efficient way for customers to order their favorite products from Chai Point.</span>
    //         <span className='block text-base font-thin'>The website will also include an administrative panel for managing CMS pages and other services in the backend, providing easy and secure access for administrators to manage the website and customer orders.</span>
    //     </div>
    //     <div className='py-3 text-3xl font-bold' >Features</div>
    //     <div className='grid grid-cols-1 gap-8 lg:grid-cols-2' >
    //         <ul className='pl-10 text-base font-thin list-disc'>
    //             <li>Streamlined registration process that is user-friendly and easy to complete</li>
    //             <li>Beautiful and user-friendly interface with a clear and logical workflow</li>
    //             <li>Capability to apply coupons and promotional offers for increased customer engagement</li>
    //             <li>Dynamic SEO titles to improve search engine rankings and visibility</li>
    //             <li>Floating WhatsApp icon for convenient online ordering and customer support</li>
    //             <li>Integration with Google Analytics for detailed tracking and analysis of user behavior</li>
    //             <li>Seamless integration with payment gateways, GPS location services, SMS gateways, and push notifications</li>
    //             <li>Ability to retheme and integrate with WordPress blogs for easy content management</li>
    //             <li>Integration with social media platforms for increased engagement and brand awareness</li>
    //             <li>Integration with VAAS and DTDC E-commerce websites for seamless order processing and fulfillment</li>
    //         </ul>
    //         <div className='lg:-mt-11' >
    //             <img src='https://www.carmatec.com/wp-content/uploads/2023/03/mchaipoint.png.webp' alt='Error' />
    //         </div>
    //     </div>
    //     <div className='py-6 text-3xl font-bold' >Solution Offered</div>
    //     <div>
    //         <span className='block text-base font-thin'>Arriving at a level of understanding of the proposed vision, Carmatec has analyzed the feasibility and anticipated outcomes thereby proposing to develop an exclusive platform that will outreach the market by offering an intuitive E-Commerce Website Front-End & CMS Admin.</span>
    //     </div>
    //     <div>
    //         <img src='https://www.carmatec.com/wp-content/uploads/2023/03/ms-chaipoint.png.webp' alt='Error' />
    //     </div>
    //     <div className='grid grid-cols-1 gap-5 lg:grid-cols-2' >
    //         <div>
    //             <div className='py-3 text-base font-bold' >ARCHITECTURE</div>
    //             <ul className='pl-10 text-base font-thin list-disc' >
    //                 <li>Distributed service stack on AWS</li>
    //                 <li>Scalable, Load Balanced and Flexible for scale</li>
    //             </ul>

    //             <div>
    //                 <div className='py-3 text-base font-bold' >SUPPORT PLATEFORMS</div>
    //                 <ul className='pl-10 text-base font-thin list-disc' >
    //                     <li>All standard operating systems</li>
    //                     <li>React JS Website Front-end (ORDER & SHOP)</li>
    //                     <li>CakePHP CMS ADMIN</li>
    //                 </ul>
    //                 <div className='my-3 text-base font-thin' >The stake holders of the Website are:</div>
    //                 <ul className='pl-10 text-base font-thin list-disc' >
    //                     <li>Customer</li>
    //                     <li>Admin</li>
    //                 </ul>
    //             </div>
    //         </div>
    //         <div>
    //             <div className='py-3 text-base font-bold' >ADMIN</div>
    //             <ul className='pl-10 text-base font-thin list-disc' >
    //                 <li>Manage CMS Pages</li>
    //                 <li>Newsletter Subscription List</li>
    //                 <li>Manage FAQs</li>
    //                 <li>Manage Job Listing</li>
    //                 <li>Manage Brands & URLs</li>
    //                 <li>Manage Users & Roles</li>
    //                 <li>Manage Customers</li>
    //                 <li>Manage Site Front-end Settings</li>
    //                 <li>Manage Reviews & Ratings</li>
    //             </ul>
    //         </div>
    //     </div>
    //     <div>
    //         <div className='pt-6 pb-3 text-3xl font-bold' >Technology</div>
    //         <div className='text-base font-thin'>ReactJS, Cake PHP, Mysql Database, UI/UX & HTML/CSS</div>
    //     </div>
    // </div>

    <div className="w-screen h-[80vh]">
      <LazyLoadImage
        src="https://cdn.dribbble.com/users/839742/screenshots/2637649/attachments/528314/under_maintenance.png"
        className="w-screen "
      />
    </div>
  );
};

export default ProjectDetails;
