import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { axiosInstence } from '../../Config/baseUrl';
import DomPurify from "dompurify"

const Faq = () => {
    const [data, setData] = useState([]);

    const getCreer = () => {
        axios
            .get(`http://192.168.1.188:9899/blog/faq-api/`, {
                headers: {
                    //Authorization: `Token ${localStorage.getItem("token")}`,
                    Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
                    //token: "6030357fc8126488bf023e910c0dbe35026608c4",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                setData(res.data?.data || []);
            })
            .catch((err) => {
                toast.error("Error fetching FAQs", err?.response?.data?.message || "An error occurred");
            });
    };

    useEffect(() => {
        getCreer();
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, []);
    // const data1 = DomPurify.sanitize(data)



    return (
        <div>
            <section className="team section-padding style-6">
                <div className="mb-40 content1">
                    <div className="container">
                        <div className="mb-20 text-center section-head style-4">
                            <small className="title_small">App Development</small>
                            <h2 className="mb-20">
                                We are ready to work and build{" "}
                                <span>on-demand solution for your business.</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <div className="content sec-content">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="section-head style-4">
                            <small className="title_small">
                                <strong>FAQ's</strong>
                            </small>
                        </div>
                        <Accordion>
                            {data?.map((faq, index) => (
                                <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header>{faq.question}</Accordion.Header>
                                    <Accordion.Body >
                                        {faq.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
