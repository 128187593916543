import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { baseUrl } from "../../../Config/baseUrl";
const Project = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Delivery app Design 1",
      type1: "App design",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/delivery-01.webp",
      link: "",
      description:
        "Find here the best delivery app solutions to delivery the products or items.",
      tags1: "Delivery app",
    },
    {
      name: "Delivery app Design 2",
      type1: "App design",
      description:
        "Find here the best delivery app solutions to delivery the products or items.",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/delivery-02.webp",
      link: "",
      tags1: "Delivery app",
    },
    {
      name: "Delivery app Design 3",
      type1: "App design",
      description:
        "Find here the best delivery app solutions to delivery the products or items.",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/operator-1.webp",
      link: "",
      tags1: "Delivery app",
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${16}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Delivery App</small>
              <h2 className="mb-20">
                <span>Top Picks For You</span>{" "}
              </h2>
              <p>We are providing best Delivery App Development service.</p>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/delivery-1.webp"
                      alt="Delivery"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Delivery Software</small>
                      <h2>
                        Essentials <span> delivered</span> to your{" "}
                        <span>doorstep</span>
                      </h2>
                      <br />
                    </div>
                    <p>
                      Aara Technologies developed a Delivery application which
                      is help you deliver all essential things at your home
                      without interactive with any person. Now a days maximum
                      people are using online platform for shopping. In this
                      COVID-19 situation 90% people prefer shopping through the
                      online portal,online website bcz they don’t want to
                      interact with any one.
                    </p>
                    <div className="d-flex align-items-center mt-50">
                      <a
                        href="/Contact"
                        rel="noreferrer"
                        className="text-white btn rounded-pill bg-blue4 fw-bold me-4"
                      >
                        <small>Get Qoute Now</small>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Delivery"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">app development</small>
                      <h2 className="mb-40">
                        <span>Delivery App</span>
                      </h2>
                    </div>
                    <p className="mb-20 text">
                      An order is typically made either through the restaurant
                      or grocer’s website or mobile app or also through the food
                      ordering company. A delivery app is helping people to
                      order there necessary goods from one place and they’ll
                      receive their product at home without interacting with
                      people.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/delivery-app.webp"
                      alt="Delivery"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Delivery"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/fast-delivery.webp"
                      alt="Delivery"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Delivery App</small>
                      <h2 className="mb-30">
                        To Get <span>Fastest Delivery </span>
                      </h2>
                    </div>
                    <p>
                      If you haven't used this service yet, you need to give it
                      a try. This delivery service simply lets you set orders
                      for weeks, days or months depending on your demand. Or you
                      can just place the order the night before and get it
                      delivered the next day as early. Be it groceries,
                      vegetables, fruits and milk, everything gets delivered. In
                      case you have set up a repeat order for a week or a month
                      and need to discontinue in between, you can opt for the
                      holiday option provided by the portal. You can resume the
                      service as and when required.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Delivery"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Download</small>
                      <h2 className="mb-40">
                        All This From The Convenience Of Your Phone. Download
                        The Aara Delivery Mobile App.
                      </h2>
                      <LazyLoadImage
                        src="https://aaratech.s3.ap-south-1.amazonaws.com/google-play.webp"
                        alt="Delivery"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/d-app.webp"
                      alt="Delivery"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Delivery"
              className="bubble2"
            />
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Projects</small>
              <h2 className="mb-20">
                <span>Our Projects</span>{" "}
              </h2>
              <p>Here are given some our projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className=" col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad !shadow-xl !shadow-gray-400">
                        <div className="img">
                          <LazyLoadImage
                            className=""
                            src={card.image}
                            alt="Delivery"
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href="#">{card.title}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.title}
                          </small>
                          <div
                            className="overflow-hidden text text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                            }}
                          >
                            {card.content}
                          </div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {/* {card.tags} */}
                            </a>
                            <a href="#" className="me-1">
                              {/* {card.tags1} */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Project;
