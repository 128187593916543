import axios from "axios";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../../Config/baseUrl";
import Sharedbutton from "../../Sharelink";
const Detailnews = () => {
  const [data1, setData2] = useState([]);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const newsData = () => {
    axios
      .post(
        `${baseUrl}/blog/news-details-api/`,
        { id: id },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data.news_details[0]);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        alert(err);
      });
  };
  useEffect(() => {
    newsData();
  }, [id]);

  const newsData2 = () => {
    axios
      .post(
        `${baseUrl}/blog/store-based-news-list-api/`,
        { store_id: 1 },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData2(res.data?.news_details);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      });
  };
  useEffect(() => {
    newsData2();
  }, []);

  const navigate = useNavigate();

  // blog commenting api

  // const formik = useFormik({
  //   initialValues: {
  //     user_name: "",
  //     email: "",
  //     website_url: "",
  //     comment: "",
  //   },

  //   onSubmit: (values) => {
  //     const reqBody = {
  //       blog_id: id,
  //       user_name: values.user_name,
  //       email: values.email,
  //       website_url: values.website_url,
  //       comment: values.comment,
  //     };
  //     axios
  //       .post(`${baseUrl}/blog/post-comment-blog`, reqBody, {
  //         headers: {
  //           token: "6030357fc8126488bf023e910c0dbe35026608c4",
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       })
  //       .then((res) => {
  //         console.log("RESPONSE ==== : ", res);
  //         alert(res.data.message);
  //       })
  //       .catch((err) => {
  //         console.log("ERROR: ====", err);
  //         alert(err.response.data.message);
  //       });
  //   },
  // });

  const sanitizedContent = DOMPurify.sanitize(data?.content);
  return (
    <main className="portfolio-page style-1">
      <Helmet>
        <title>{data?.Title}</title>
        <meta name="description" content={data?.meta_description} />
        <meta name="keywords" content={data?.keyword} />
      </Helmet>
      <section className="bg-white portfolio-projects section-padding style-1">
        <div className="container mad44">
          <div className="mb-40 text-center section-head style-4 col-lg-8">
            <small className="title_small">Blogs</small>
            <h2 className="mb-20">
              <span> {data?.Title} </span>
            </h2>
            <span className="mb-20"> {data?.Title} </span>
            <br />
            <LazyLoadImage src={data?.images} className="mb-30" />
            <div
              className="text-start"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
            <Sharedbutton url={window.location.href} />
          </div>

          <div className="col-lg-4 mt-70 mad88 ">
            <h6 className="text-center mb-50">RECENT NEWS</h6>
            <div className="mb-50 ">
              {data1?.slice(0, 8)?.map((card) => (
                <>
                  <div
                    className="portfolio-card madcaf1"
                    onClick={() => {
                      navigate(`/news/${card?.id}/${card?.slug}`);
                    }}
                  >
                    <div className="img">
                      <LazyLoadImage
                        src={card?.images}
                        alt=""
                        style={{ width: "8rem" }}
                      />
                    </div>
                    <div className="info1">
                      <h5>
                        <a
                          href=""
                          className=""
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflowX: "hidden",
                            width: "280px",
                          }}
                        >
                          {card?.Title}
                        </a>
                      </h5>
                      <div className="text text1">{card?.meta_description}</div>
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Detailnews;
