import React, { useEffect, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";
import AutomatedremindersystemFAQ from "../../../FAQPages/ServicesFAQ/Automatedremindersystem";
const Project = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Delivery App",
      type1: "App development",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/delivery-01.webp",
      link: "/page-single-project-5/",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      tags: "Automated reminder app",
    },
    {
      name: "Mengo restaurant",
      type1: "App DEVELOPMENT",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/delivery-02.webp",
      link: "/page-single-project-5/",
      tags: "Automated reminder app",
    },
    {
      name: "Grocery App",
      type1: "App DEVELOPEMNT",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      image: "https://aaratech.s3.ap-south-1.amazonaws.com/operator-1.webp",
      link: "/page-single-project-5/",
      tags: "Automated app",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${32}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Software</small>
              <h2 className="mb-20">
                <span>Automated Reminder System</span>
              </h2>
              <p>
                We are providing best Automated Reminder System software
                service.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <h2 className="mb-20">
                  <span> Reminder Software </span> You Can Trust
                </h2>
                <p>
                  The reminder system is designed for giving proper notification
                  for any important events, birthdays, Offices meeting and etc.
                  Reminder software is a type of time management computer
                  software that is designed to alert the user of important
                  events that they have input to the program. Most programs
                  provide a calendar or list view of events, as well as a
                  reminding technique.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/reminder-2.webp"
                  className="imgmad mt-30"
                  alt="Automated"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-evenly">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/reminder-1.gif"
                      alt="Automated"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2>
                          <span> Appointment Reminder </span>
                        </h2>
                        <br />
                      </div>
                      <p>
                        Appointment Reminder system is basically use for any
                        Office Meetings or any official events, You can set date
                        of events or meeting appointment reminder will send you
                        reminder on the same date by which you can attend
                        meeting and Events on time.
                      </p>
                      <h5 className="fw bold">
                        This comes with several advantages as well:
                      </h5>
                      <br />
                      <ul>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>Open Google Calendar app.</h6>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>
                            On the bottom of right side ,tap create,Reminder.
                          </h6>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>Enter you Reminder date</h6>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>Select time and frequency.</h6>
                        </li>
                        <li className="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <h6>
                            In top right sight there will be save button click
                            on that.
                          </h6>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>And the reminder “ll be set.</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-evenly">
                <div className="order-2 col-lg-5 order-lg-0 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2 className="mb-40">
                          <span>Event Scheduling</span> And{" "}
                          <span>Reminder</span>
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        Event scheduling & Reminder means this is software which
                        is developed by Aara Technology for the scheduling
                        reminder for any upcoming event . If you have to attend
                        an event in the up coming time and you are afraid not to
                        miss then this software will help and remind you for the
                        specific events and meeting through your personal email
                        or by sending msg on your personal contact no.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/event-reminder.webp"
                      alt="Automated"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Automated"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/birthday.webp"
                      alt="Automated"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2 className="mb-30">
                          <span>Birthday Reminder</span>
                        </h2>
                      </div>
                      <p>
                        Aara Technologies are developed a Birthday Reminder
                        software that helps you remember important dates like
                        Birthdays, Anniversaries, and about holidays also.
                      </p>
                      <h4>How do you set birthday Reminder</h4>
                      <br />
                      <p>
                        Double click on tap of Birthday and open recurring item
                        dialog box. Click open the series and click on OK button
                        , On the recurring event tab , In the option group ,
                        change the reminder time. You can set your reminder date
                        up to 1 or 2 week before.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-evenly">
                <div className="order-2 col-lg-6 order-lg-0 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <h2 className="mb-40">
                          <span>Task Reminder</span>
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        Task Reminder System will remind you when to do item is
                        reaching. When you are working or doing something, you
                        might be forgetting to do your task at the same time. I
                        also have this situation, so I create Task Reminder
                        which help you guys to remember your tasks for the
                        specific given time. All you need to do is create tasks,
                        schedule a time to alert, This software system will help
                        you to remember the time for your task by which you can
                        do your task on given time schedule and also you can
                        complete your specific task on time.
                      </p>
                      <h5 className="fw bold">
                        This comes with several advantages as well:
                      </h5>
                      <br />
                      <ul>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>Easy to use.</h6>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>Automatic.</h6>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>Reliable</h6>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <h6>Customizable</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/task-manager-1.webp"
                      alt="Automated"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Automated"
              className="bubble2"
            />
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Software</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here our some projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className=" col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad !shadow-xl !shadow-gray-400">
                        <div className="img">
                          <LazyLoadImage
                            className=""
                            src={card.image}
                            alt="Automated"
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href="#">{card.title}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.title}
                          </small>
                          <div
                            className="overflow-hidden text text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                            }}
                          >
                            {card.content}
                          </div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {/* {card.tags} */}
                            </a>
                            <a href="#" className="me-1">
                              {/* {card.tags1} */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <AutomatedremindersystemFAQ />
    </div>
  );
};

export default Project;
