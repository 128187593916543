import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SoftwaredevelopmentFAQ from "../../../FAQPages/ServicesFAQ/Software development";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";
import { toast } from "react-toastify";

const Project = (props) => {
  const [meta, setMeta] = useState();

  const [cards, setCards] = useState();

  const ProjectData = (page_title) => {
    axios
      .get(`${baseUrl}/blog/project-image-api/?project_id=${17}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          // Authorization: `Token ${localStorage.getItem("token")}`,
          /* "Access-Control-Allow-Origin": "*" */
        },
      })
      .then((res) => {
        setCards(res?.data?.data || []);
      })
      .catch((err) => {
        toast.error(
          "Error fetching FAQs",
          err?.response?.data?.message || "An error occurred"
        );
      });
  };
  // const cards = [
  //   {
  //     name: "Root Less Auto",
  //     type1: "Web development",
  //     image:
  //       "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rootless-auto.png",
  //     link: "",
  //     tags: "Software",
  //   },
  //   {
  //     name: "RTI Web",
  //     type1: "WEBSITE DEVELOPMENT",
  //     image:
  //       "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rti-web.png",
  //     link: "",
  //     tags: "Software",
  //   },
  //   {
  //     name: "National Research Program",
  //     type1: "Web DEVELOPEMNT",
  //     image:
  //       "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/nrp-web.png",
  //     link: "",
  //     tags: "Software",
  //   },
  // ];

  useEffect(() => {
    ProjectData();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${35}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta?.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem?.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem?.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Software development</small>
              <h2 className="mb-20">
                <span> Software Development </span> Company{" "}
              </h2>
              <p>
                We have an experienced team of Best Software Development Company
                in Entire India and worldwide.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Software Development</small>
                <h2 className="mb-20">
                  <span>Best Software Development Company In Noida</span>{" "}
                </h2>
                <p>
                  We are the best software development company in Noida, Lucknow
                  and the entire of India provide end-to-end software
                  development services designed to grow businesses of all types,
                  increase their ROI, and stay ahead of the competition. Whether
                  it is technology consulting, custom software engineering,
                  system integration, software testing, quality assurance, or
                  software support—we got you covered.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/software-1.webp"
                  className="imgmad mt-30"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/software-2.webp"
                      alt="Software development"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">
                        Software Development
                      </small>
                      <h2 className="mb-40">
                        Full Range Of Software Engineering Services
                      </h2>
                    </div>
                    <h5>Best Custom Software Development Company in noida</h5>
                    <br />
                    <p>
                      Whether you are just a start-up or a well-established
                      enterprise, our experts are ready to assist you at every
                      stage of the software development life cycle—from initial
                      planning & consulting to final development & support.
                    </p>
                    <h5>Best Mobile Application Development Company</h5>
                    <br />
                    <p>
                      Develop highly usable, powerful, and feature-packed mobile
                      apps that solve various business problems, reinforce your
                      brand and attract new users for you. We build hybrid,
                      native, and cross-platform mobile apps that run on all
                      major operating systems.
                    </p>
                    <h5>Web Application Development</h5>
                    <br />
                    <p>
                      Our software developers have rich domain expertise in
                      building secure and feature-packed web applications. We
                      provide software development services for many verticals
                      and business domains, including e-Learning, e-Commerce,
                      Finance, AdTech, Entertainment, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt="Software development"
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Web development</small>
                      <h2 className="mb-40">
                        <span> Dedicated Development </span> Center
                      </h2>
                    </div>
                    <p className="mb-20 text">
                      Use our dedicated Software development teams to rapidly
                      scale up or down your Software development resources,
                      reduce time to market, and cut development costs. We have
                      a solid process in place in order to ensure total control
                      and visibility. Our Software development teams bring
                      strong technology insights, expertise and knowledge to the
                      platform of choice, apart from valuable experience across
                      software solutions, architecture, and industry domains.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/software-3.webp"
                      className="w-75"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Software development"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/software-4.webp"
                      alt="Software development"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>QA Automation </span> Service
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      You can utilize our testing automation services to release
                      software faster without sacrificing any quality. You can
                      leverage our in-house automation framework to quickly test
                      APIs, functionality, or mobile applications with
                      unparalleled speed & robustness. You can hire our QA &
                      testing experts to ensure the highest level of quality of
                      your Software. We provide standalone Software QA & testing
                      services via dedicated teams with the right expertise in
                      order to meet the specific needs of a project.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>

          <div className="integration pt-30" data-scroll-index="3">
            <div className="text-center section-head style-4">
              <small className="title_small">Steps For Done</small>
              <h2 className="mb-10">
                Our <span> Working Process</span>{" "}
              </h2>
            </div>
            <div className="container">
              <div className="content">
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/provisioning.png"
                    alt="Software development"
                    className="mt-30"
                  />
                  <h3 className="text-center">1. Provisioning</h3>
                </div>
                <div className="img">
                  <h3 className="text-center">2. Strategy</h3>
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy.png"
                    alt="Software development"
                    className="mt-30"
                  />
                </div>
                <div className="img">
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/design%26developement.png"
                    alt="Software development"
                    className="mt-30"
                  />
                  <h3 className="text-center">3. Development</h3>
                </div>
                <div className="img">
                  <h3 className="text-center">4. Go Live</h3>
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/go-live.png"
                    alt="Software development"
                    className="mt-30"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Software development</small>
              <h2 className="mb-20">
                Our <span>projects</span>
              </h2>
              <p>Here are given some our latest projects.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards?.map((card) => (
                    <div className=" col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad !shadow-xl !shadow-gray-400">
                        <div className="img">
                          <LazyLoadImage
                            className=""
                            src={card.image}
                            alt="Software development"
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href="#">{card.title}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.title}
                          </small>
                          <div
                            className="overflow-hidden text text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                            }}
                          >
                            {card.content}
                          </div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {/* {card.tags} */}
                            </a>
                            <a href="#" className="me-1">
                              {/* {card.tags1} */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <SoftwaredevelopmentFAQ />
    </div>
  );
};

export default Project;
