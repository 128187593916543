import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import FlareIcon from "@mui/icons-material/Flare";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import axios from "axios";
import Sharedbutton from "../Sharelink";
import gtag_report_conversion from "../../GoogleConversionFn";
import { Loader } from "../../Loader";
import { baseUrl } from "../../Config/baseUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import JobOpeningFAQ from "../../FAQPages/Jobopening";
import { Refresh } from "@mui/icons-material";

const generateCaptcha = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";
  for (let i = 0; i < 6; i++) {
    captcha += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return captcha;
};
const Jobopening = (props) => {
  const [data, setData] = useState([]);
  const [cv, setCv] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [meta, setMeta] = useState();
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaError, setCaptchaError] = useState(false);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      candiate_messages: "",
      candiate_type: "",
      experience: "",
      cv: "",
      captcha_input: "",
    },

    onSubmit: (values, action) => {
      if (values.captcha_input !== captcha) {
        setCaptchaError(true);
        return;
      }
      const reqBody = new FormData();
      reqBody.append("first_name", values.first_name);
      reqBody.append("last_name", values.last_name);
      reqBody.append("email", values.email);
      reqBody.append("mobile_no", values.mobile_no);
      reqBody.append("candiate_messages", values.candiate_messages);
      reqBody.append("candiate_type", "ONLINE/AARATECH");
      reqBody.append("candiate_messages", values.candiate_messages);
      reqBody.append("experience", values.experience);
      reqBody.append("cv", cv);
      reqBody.append("cv_check", true);
      setIsPending(true);
      axios
        .post(
          "https://b1.bhaaraterp.com/Api_data/career-page-API-with-CV-attachment/",
          reqBody,
          {
            headers: {
              Token: "c0951c5b80621b873302bc6d68669bdfe7876f73",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setIsPending(false);
          alert(res.data.message);
          window.location.href = `https://crmapp.bhaaraterp.com/whatsapp?template=career_form&name=${
            values.first_name + " " + values.last_name
          }&mobile=${values.mobile_no}&email=${
            values.email
          }&callback_url=https://aaratechnologies.com/&access_token=b8bb5f83ea8a4ab4886394dbd00dbd97`;
          gtag_report_conversion("https://aaratechnologies.com/");
        })
        .catch((err) => {
          setIsPending(false);
          console.log("ERROR: ====", err);
          alert(err.response.data.message);
        });
      action.resetForm();
    },
  });
  const refreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setCaptchaError(false); // Reset captcha error on refresh
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const blogData = () => {
    axios
      .post(
        `${baseUrl}/blog/store-based-jobs-list-api/`,
        { store_id: 1 },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "c0951c5b80621b873302bc6d68669bdfe7876f73",
          },
        }
      )
      .then((res) => {
        setData(res.data?.news_details);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        alert(err.response.data.message);
      });
  };

  useEffect(() => {
    blogData();
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${147}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);

  return (
    <div className="position-relative">
      {isPending && <Loader />}
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1 ">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>Job Opening</span>
              </h2>
            </div>
          </div>
        </section>
        <section className="relative about section-padding style-4 position-relative">
          <div className="content trd-content">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <h3 className="mb-30">We Are With You In Your Success.</h3>
                    <p>
                      Welcome to Aara Technologies Pvt. Ltd. It Takes a new kind
                      of Intelligence to nevigate a new world.
                    </p>
                    <ul>
                      <li className="d-flex">
                        <FlareIcon /> <h5>Website:</h5>
                      </li>
                      <p>
                        <a href="https://aaratechnologies.com/">
                          https://aaratechnologies.com/
                        </a>
                      </p>
                      <li className="d-flex align-items-center">
                        <FlareIcon />
                        <h5>Send Your Resume At:</h5>
                      </li>
                      <p>hr@aaratechnologies.in</p>
                    </ul>
                  </div>
                  <Accordion defaultActiveKey="0" className="mb-40">
                    <Accordion.Item
                      style={{ position: "relative" }}
                      eventKey="0"
                    >
                      <Accordion.Header>
                        <h2 className="color-blue4">Apply for jobs</h2>
                      </Accordion.Header>
                      <Accordion.Body className="!h-full">
                        <form
                          onSubmit={formik.handleSubmit}
                          className="form"
                          method="post"
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-20 form-group">
                                <input
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  className="form-control"
                                  placeholder="First Name"
                                  value={formik.values["first_name"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-20 form-group">
                                <input
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  className="form-control"
                                  placeholder="last Name"
                                  value={formik.values["last_name"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-20 form-group">
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  placeholder="Email"
                                  value={formik.values["email"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-20 form-group">
                                <input
                                  type="text"
                                  name="mobile_no"
                                  id="mobile_no"
                                  className="form-control"
                                  placeholder="Mobile number"
                                  value={formik.values["mobile_no"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-20 form-group">
                                <input
                                  type="text"
                                  name="candiate_messages"
                                  id="candiate_messages"
                                  className="form-control"
                                  placeholder="Message"
                                  value={formik.values["candiate_messages"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-20 form-group">
                                <input
                                  type="text"
                                  name="experience"
                                  id="experience"
                                  className="form-control"
                                  placeholder="Experience (In year)"
                                  value={formik.values["experience"]}
                                  onChange={formik.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="mb-20 form-group">
                                <input
                                  type="file"
                                  name="cv"
                                  id="cv"
                                  className="form-control"
                                  accept="application/pdf, image/jpeg"
                                  placeholder="cv"
                                  onChange={(e) => setCv(e.target.files[0])}
                                />
                              </div>
                            </div>
                            <div className="flex items-center justify-center gap-3 pt-1 mx-auto captcha-section ">
                              <input
                                type="text"
                                name="captcha_input"
                                id="captcha_input"
                                placeholder="Enter CAPTCHA"
                                value={formik.values["captcha_input"]}
                                onChange={formik.handleChange}
                                className="lg:form-control  !w-[150px] border rounded py-3 px-2"
                              />
                              <div className="flex items-center justify-center pt-2">
                                <span className="p-3 border border-blue-400 rounded ">
                                  {captcha}
                                </span>
                                <button
                                  type="button"
                                  onClick={refreshCaptcha}
                                  className="ml-2 text-center text-blue-500 "
                                >
                                  <Refresh fontSize="large" />
                                </button>
                              </div>
                              {captchaError && (
                                <div className="mt-2 text-sm text-red-500">
                                  Captcha is incorrect!
                                </div>
                              )}
                            </div>
                            <div className="text-center col-lg-12 !pt-2">
                              <input
                                type="submit"
                                className="btn rounded-pill blue5-3Dbutn hover-blue2 fw-bold md1"
                                value="Send Your Request"
                              />
                            </div>
                          </div>
                        </form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="col-lg-6">
                  <h3 className="mb-30">Here our company jobs Positions.</h3>
                  <p>
                    Open for job postions in Aara Technologies Pvt. Ltd. It
                    Takes a new kind of Intelligence to nevigate a new world.
                  </p>
                  <div className="info">
                    <Accordion className="mb-20" defaultActiveKey={0}>
                      {data?.map((card, index) => (
                        <Accordion.Item key={index}>
                          <Accordion.Header onClick={() => setActiveKey(index)}>
                            <h5>{card.Title}</h5>
                          </Accordion.Header>
                          <Accordion.Body
                            collapsible={true}
                            eventKey={index}
                            hidden={activeKey !== index}
                          >
                            <ol>
                              <div>
                                <br />
                                <LazyLoadImage src={card.images} />
                              </div>
                              <li>
                                <h6>Year Of Experience:</h6>
                                <br />
                                <p className="fw-bold">{card.Experience}</p>
                              </li>
                              <li>
                                <h6>Job Date:</h6>
                                <br />
                                <p className="fw-bold">{card.created_at}</p>
                              </li>
                              <li>
                                <h6>Eligibility:</h6>
                                <br />
                                <div
                                  className="fw-bold"
                                  dangerouslySetInnerHTML={{
                                    __html: card.Content,
                                  }}
                                ></div>
                              </li>
                              <Sharedbutton
                                url={`${window.location.href}`}
                                className="my-2"
                              />
                            </ol>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <JobOpeningFAQ />
    </div>
  );
};

export default Jobopening;
