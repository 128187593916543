import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import MagentodevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Magentodevelopment";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";
const Mlm = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Olive Kalyan Mandap Site",
      type1: "Web development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/okm-web.png",
      link: "",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      tags: "Web development",
    },
    {
      name: "RTI website",
      type1: "WEBSITE DEVELOPMENT",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/rti-web.png",
      link: "",
      tags: "Web development",
    },
    {
      name: "OEM SPA Website",
      type1: "Web DEVELOPEMNT",
      description:
        "Trust our top minds to eliminate workflow pain points, implement new tech & app.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/competitive-cyclist.png",
      link: "",
      tags: "Web development",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${43}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">
                Magento Software Development Company
              </small>
              <h2 className="mb-20">
                <span>Magento Software Development </span>
              </h2>
              <p>
                We have an experienced team of Magento Software Development.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Magento Software</small>
                <h2 className="mb-20">
                  <span>Magento Software Development </span> Company
                </h2>
                <p>
                  Magento Framework is an eCommerce platform built on open
                  source technology that provides online merchants with a
                  flexible shopping cart system, as well as control over the
                  looks, and content, and it can give advanced functionality of
                  their online store. Magento is designed to be utilized as an
                  application by someone who isn't a developer.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/magento.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-development.webp"
                      alt=" magentodevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Magento Development</small>
                      <h2 className="mb-30">
                        <span> Magento Software Development </span>
                      </h2>
                    </div>
                    <p>
                      Magento is a PHP programming language and leverages
                      elements of the Zend framework and the
                      model-view-controller(MVC) architecture. Magento runs on
                      the MySQL relational database, and Magento schema and
                      tables are included in the Magento installation package.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_lines.png"
              alt=" magentodevelopment"
              className="lines1"
            />
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" magentodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="info">
                  <div className="section-head style-4">
                    <h2 className="mb-30">
                      Why Should You Choose{" "}
                      <span> Magento For Your Online Store Development? </span>
                    </h2>
                  </div>
                  <p className="mb-40 text">
                    This plan of MLM is also known as the Forced Matrix Plan or
                    Ladder Plan. This plan is very much similar to a pyramid
                    structure. In this plan, the MLM company can set various
                    levels like 2X2 or 3X5, etc. The organizational tree
                    structure is well-defined in its width and depth with a
                    specified ‘n * n’ value. MLM Matrix Plan Calculator or
                    forced matrix calculator, calculate commissions for your
                    multi-level marketing efforts. But it has one limitation,
                    the number of forerunners in an organization setup.And we
                    are the best MLM software company that can provide the best
                    Matrix MLM plan software for your business.
                  </p>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/online-store.png"
                            className="mb-20"
                          />
                          <Card.Subtitle>Build Scalable Store</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/product-architecture.png"
                            className="w-40 mb-20"
                          />
                          <br />
                          <Card.Subtitle>
                            Create Robust Architecture
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/online-store+(1).png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>
                            Manage Multiple Storefronts
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/api.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Access Flexible APIs</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/omnichannel.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Target Omnichannel</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/extensions-folder.png"
                            className="mb-20 "
                          />
                          <Card.Subtitle>
                            Leverage Ready to Use Extensions
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-20">
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/open-source.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>OpenSource Platform</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/fast-forward.png"
                            className="w-40 mb-20"
                          />
                          <br />
                          <Card.Subtitle>
                            Fast <br />
                            Rendering
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/external-payment-working-from-home-justicon-lineal-justicon.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Multiple payment Method</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/in-inventory.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Inventory Control</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/offline.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>
                            Offline <br />
                            Service
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/external-shipping-commerce-prettycons-lineal-prettycons-1.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>MultipleShipping Method</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/open-source.png"
                            className="mb-20 w-25"
                          />
                          <Card.Subtitle>OpenSource Platform</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/full-tool-storage-box-.png"
                            className="w-40 mb-20"
                          />
                          <br />
                          <Card.Subtitle>Utility Tool</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/domain.png"
                            className="mb-20 "
                          />
                          <Card.Subtitle>
                            Boost Website Performance
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/iot-sensor.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>IOT</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/overview-pages-4.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Manage Indexers</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <Card.Body>
                          <LazyLoadImage
                            src="https://aaratech.s3.ap-south-1.amazonaws.com/megento4/search-client.png"
                            className="w-40 mb-20"
                          />
                          <Card.Subtitle>Powerful Search</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" magentodevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-app.webp"
                      alt=" magentodevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">App Development</small>
                      <h2 className="mb-30">
                        <span>Magento App Development</span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Looking for a reliable partner to deliver custom web and
                      Magento development services? Aara Technology is providing
                      services for Magento App Development. Aara technology
                      provides you services with an advanced version of the
                      Magento Program. Magento is used for designing or
                      developing e-Commerce websites and applications, It is
                      used to develop Market place websites where sellers or
                      buyers can sell or buy there all their products in one
                      place and it will easy to use also, It has advanced
                      features also like Easy shipping on any places, Supporting
                      multi-languages, support multiple currencies, etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" magentodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">MLM software</small>
                      <h2 className="mb-30">
                        Custom <span> Magento Development </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      With Aara Technologies' expertise in Magento customization
                      services, you can be sure there’s no limit to what you can
                      do or what you want to do. From lean startups to mid-sized
                      businesses on the growth trajectory to industry-leading
                      enterprises, our Magento customization service set our
                      customers on the road to new-age commerce. Expect us to
                      deliver everything you are looking for and more. Like
                      having your eCommerce site perform impressively on mobile,
                      increasing merchandising capabilities or integrating
                      seamlessly third-party offerings. Our Magento
                      customization competence makes sure your eCommerce store
                      is designed and works exactly the way you visualize it –
                      super-friendly, easy and secure. We go to great lengths to
                      ensure your own customers can make purchases from any
                      device with great ease and peace of mind that only a
                      robust solution can provide.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-custom-development.webp"
                      alt=" magentodevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" magentodevelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-ui-theme.webp"
                      alt=" magentodevelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">Ui Development</small>
                      <h2 className="mb-30">
                        <span>Magento Theme UI </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      We are designed a customized UI for your store as per your
                      requirement. Themes are designed to override or customize
                      view layer resources, provided initially by modules or
                      libraries. Themes are implemented by different vendors
                      (frontend developers) and intended to be distributed as
                      additional packages for the Magento system similar to
                      other components.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt=" magentodevelopment"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0">
                  <div className="info">
                    <div className="section-head style-4">
                      <small className="title_small">MLM software</small>
                      <h2 className="mb-30">
                        <span> Multi Vendor Marketplace </span> Development
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      As the name of the Multi-vendor Market place we can
                      understand that it, A place where we can take all goods
                      our services as per requirement. The multi-vendor
                      marketplace is the place that helps seller sell their all
                      products in a single place without wasting his/ her time.
                      It will help you save time. And the same for Buyers also
                      buyers can purchase all goods from one place. Multi-vendor
                      marketplaces are supporting multiple Logistics for sending
                      products to the customers and also supporting multiple
                      currencies and multiple languages also.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-market-place.webp"
                      alt=" magentodevelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt=" magentodevelopment"
              className="bubble2"
            />
          </div>
        </section>

        <section className="mb-20 team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <h2 className="mb-20">
                  <span>
                    We are ready to work and build an on-demand solution for
                    your business.{" "}
                  </span>
                </h2>
                <Link to="/contact" target="_blank">
                  <Button className="bg-blue4">Get a Quote</Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div className="content sec-content mb-30">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h5>
                      I am looking for an eCommerce platform to start up my own
                      business, any solution for this?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    Most new online stores use Magento. And yes, it is the best
                    eCommerce platform to get your company off the ground.
                    Magento is a very popular eCommerce platform in the world.
                    There are many advantages of this platform that make it
                    worthy of being at the top of this list. Furthermore, the
                    release of Magento 2 has increased the visibility of the
                    platform, allowing multiple vendors to develop more viable
                    B2B eCommerce solutions.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h5> Why should I choose Magento over WooCommerce?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    Magento caters to companies rather than WooCommerce, which
                    pitches itself as a community solution. However, this does
                    not rule out the possibility that small businesses will
                    benefit from its features. Magento comes in two flavors, a
                    free community edition which is extremely robust in itself,
                    and an enterprise edition for larger companies. It seems
                    that Magento is geared more toward developers than casual
                    users due to its steep learning curve. As a result, you have
                    to select it on WooCommerce.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h5>Are you into Magento mobile app development?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, we are in Magento development. Our development has
                    already gained a lot of traction among companies and public
                    platforms. Native and cross-platform app development, UI and
                    UX consulting and much more are just some of the services we
                    provide. To build an excellent mobile app, we work in two
                    ways, you can either build a native mobile app for each
                    platform or you can create a single cross-platform mobile
                    app that supports multiple platforms.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <h5>
                      Can I further implement features in your Marketplace
                      solution?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, you definitely can. Our marketplace has a diverse set
                    of features and plugins that are extremely robust and easy
                    to implement.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h5>Are you into enterprise-level project development?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes. We have partnered with a wide range of enterprise-level
                    customers in sectors such as telecommunications,
                    pharmaceuticals, and vehicles. Some of our customers are
                    Nokia, Tech Mahindra, and Apollo Hospitals.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">
                Magento Software Development Company
              </small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here are given our some projects to know about our work.</p>
            </div>

            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt=" magentodevelopment"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="text">{card.description}</div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <MagentodevelopmentFAQ />
    </div>
  );
};

export default Mlm;
