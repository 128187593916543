import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AngulardevelopmentFAQ from "../../../FAQPages/TechnologiesFAQ/Angulardevelopment";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";

const Angular = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Ketomeal web",
      type1: "Web development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/Ketomeals.png",
      tags: "Angular web",
    },
    {
      name: "JMD Fire Web",
      type1: "Web development",

      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/jmdfire.png",
      tags: "Angular web",
    },
    {
      name: "CPS Jamshedpur",
      type1: "Web development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/cpsjamshedpur.png",
      tags: "Angular web",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${54}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                <span>Angular Development</span> Company
              </h2>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="mb-40 content1">
            <div className="container">
              <div className="mb-20 text-center section-head style-4">
                <small className="title_small">web app development</small>
                <h2 className="mb-20">
                  <span>Angular </span> Development
                </h2>
                <p>
                  Aara technologies is an AngularJS development company with a
                  knack for creating scalable, easy-to-take-care of, and
                  powerful websites & web applications. We blend our extensive
                  experience with innovation to unravel the complex business
                  problems of diverse industries. Our cost-effective AngularJS
                  solutions are designed to satisfy the entire requirements of
                  small, medium, large, and startup-level enterprises.
                </p>
                <p>
                  Our strength lies in our greatest practices and technical
                  expertise. We understand the changing industry trends and
                  therefore got to build visually appealing and high-performance
                  AngularJS sites. Using the AngularJS framework, we've
                  developed thousands of business websites, online marketplaces,
                  enterprise-grade applications, etc.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/angular.webp"
                  className="imgmad"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about section-padding style-4">
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/google-trends.webp"
                      alt="Angular"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>What Is AngularJS?</span>
                      </h2>
                    </div>
                    <p className="text">
                      Developed by Google, AngularJS is a scalable web
                      application framework that is widely used for creating
                      dynamic web applications. Based on JavaScript, this
                      open-source framework is quite popular among developers
                      for single-page application development. Do you want to
                      know what's special about this front-end framework? Here
                      come the best features of AngularJS software:
                    </p>
                    <ol>
                      <li>Cross-browser support</li>
                      <li>Model-View-Controller (MVC) architecture</li>
                      <li>Allows the development of rich (RIA)</li>
                      <li>Fast application development</li>
                      <li>Code reusability</li>
                      <li>User interface with HTML</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          AngularJS <br />
                          Website Design
                        </span>
                      </h2>
                    </div>
                    <p className="text">
                      We are globally known for creating clean, eye-catching,
                      and responsive designs that entice users on all devices
                      and boost conversions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/angular-web-app.webp"
                      alt="Angular"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Angular"
              className="bubble"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/angular-spa.webp"
                      alt="Angular"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          Single Page <br />
                          Applications
                        </span>
                      </h2>
                    </div>
                    <p className="mb-40 text">
                      Our AngularJS specialists. proficiently use this framework
                      to develop single-page applications like Gmail.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Angular"
              className="bubble"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>
                          AngularJS
                          <br /> Plugin Development
                        </span>
                      </h2>
                    </div>
                    <p className="text">
                      We have years of experience in developing custom AngularJS
                      plugins. Avail of our solutions to rework your website
                      performance.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/anular-plugin.webp"
                      alt="Angular"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Angular"
              className="bubble"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/e-commerce-app.webp"
                      alt="Angular"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>AngularJS ECommerce Solutions</span>
                      </h2>
                    </div>
                    <p className="text">
                      Aara Technologies is adept at developing revenue-driven
                      eCommerce websites in AngularJS. We combine the features
                      of this framework with appropriate shopping cart software.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Angular"
              className="bubble"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Custom Angular Website Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      With exhausted knowledge of AngularJS, we develop
                      customized websites that completely fit your brand image
                      and unique requirements.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/demo1.webp"
                      alt="Angular"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Angular"
              className="bubble"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/time.png"
                      alt="Angular"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Real-Time Application Development</span>
                      </h2>
                    </div>
                    <p className="text">
                      We leverage AngularJS to create real-time applications
                      that deliver an exciting user experience on various
                      devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Angular"
              className="bubble"
            />
          </div>
          <div className="content trd-content bgmad">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>Maintenance & Support</span>
                      </h2>
                    </div>
                    <p className="text">
                      We extend great technical support to form sure that your
                      website is updated with new features and doesn't face
                      downtime issues even for a moment.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/support.webp"
                      alt="Angular"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Angular"
              className="bubble"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/angular-web-app.webp"
                      alt="Angular"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info">
                    <div className="section-head style-4">
                      <h2 className="mb-30">
                        <span>AngularJS Consulting Services</span>
                      </h2>
                    </div>
                    <p className="text">
                      Get expert advice from the world's best AngularJS
                      consultants. Our AngularJS consulting solutions are
                      designed to assist you to grab the utmost advantage of
                      AngularJS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="Angular"
              className="bubble"
            />
          </div>
          <section className="team section-padding style-6">
            <div className="mb-40 content1">
              <div className="container">
                <div className="mb-20 text-center section-head style-4">
                  <small className="title_small">web development</small>
                  <h2 className="mb-20">
                    <span>
                      Support & Development Team Is Known For Its Good Service &
                      Experience{" "}
                    </span>
                  </h2>
                  <p>
                    From purchase to fulfillment – manage the entire order
                    process via the app. Scroll the summaries, or search by
                    customer or order specifics. Tap into the details to see
                    itemized billing, begin fulfillment, and change order
                    status.
                  </p>
                  <LazyLoadImage
                    src="https://aaratech.s3.ap-south-1.amazonaws.com/prouduct-support.png"
                    className="imgmad"
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <h2 className="mb-20">
                Our <span>Projects </span>
              </h2>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className="col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad">
                        <div className="img">
                          <LazyLoadImage
                            src={card.image}
                            alt="Angular"
                            style={{ objectFit: "contain" }}
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href={card.link}>{card.name}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.type1}
                          </small>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {card.tags}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <AngulardevelopmentFAQ />
    </div>
  );
};

export default Angular;
