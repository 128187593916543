import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Config/baseUrl";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

const NodejsdevelopmentFAQ = () => {
  const [faq, setFaq] = useState([]);
  const [expanded, setExpanded] = useState(null);

  const getFaq = () => {
    axios
      .get(`${baseUrl}/blog/faq-api/`, {
        headers: {
          /* Authorization: `Token ${localStorage.getItem("token")}`, */
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          page_id: "55",
        },
      })
      .then((res) => {
        setFaq(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error fetching FAQs", err?.response?.data?.message);
      });
  };

  const handleAccordionChange = (panel) => {
    setExpanded(expanded === panel ? null : panel);
  };

  useEffect(() => {
    getFaq();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center gap-10 pt-10 w-[100vw] ">
      <div className="flex flex-col w-screen gap-10">
        <p className="font-serif text-3xl font-bold text-center lg:text-4xl">
          Frequently Asked Questions
        </p>

        <div className="flex flex-col items-center justify-center gap-3">
          {faq?.map((faqItem, index) => (
            <Accordion
              key={index}
              expanded={expanded === index} // Check if this accordion is expanded
              onChange={() => handleAccordionChange(index)} // Toggle on change
              className={`${
                expanded === index
                  ? " !text-white !transition-all !duration-700 "
                  : "!border-l-4 !border-[#6E4DEE]"
              } w-[60%] !rounded-r-2xl`}
            >
              <AccordionSummary
                expandIcon={
                  expanded === index ? (
                    <RemoveCircleOutlineRoundedIcon className="!text-white" />
                  ) : (
                    <AddCircleOutlineRoundedIcon />
                  )
                }
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                className={`${
                  expanded === index
                    ? "!bg-[#6E4DEE] !text-white !rounded-tr-xl"
                    : "!border-2 !border-[#6E4DEE] !bg-[#efedf9] !rounded-r-xl"
                } text-xl !font-bold`}
              >
                <Typography className="!font-semibold">
                  {faqItem.question}
                </Typography>
              </AccordionSummary>

              <Collapse in={expanded === index}>
                {/* Divider is only shown when the accordion is expanded */}
                {expanded === index && (
                  <>
                    <AccordionDetails>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: faqItem.answer }}
                        className="!text-black"
                      >
                        {/* {faqItem.answer
                          .replace(/<[^>]+>/g, "") 
                          .replace(/&nbsp;/g, " ") 
                          .replace(/&amp;/g, " ")}{" "} */}
                      </Typography>
                    </AccordionDetails>
                  </>
                )}
              </Collapse>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NodejsdevelopmentFAQ;
