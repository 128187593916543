import React, { useEffect, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomcrmdevelopmentFAQ from "../../../FAQPages/ServicesFAQ/Customcrmdevelopment";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";
const Project = (props) => {
  const [meta, setMeta] = useState();
  const cards = [
    {
      name: "Product Inventory mangement Software",
      type1: "Software development",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-inventory-management.jpg",
      link: "",
      description:
        "Product Inventory management Software is the part of cloud based erp software. ",
      tags: "Cloud based software",
    },
    {
      name: "Product human resource management App",
      type1: "Software DEVELOPMENT",
      description:
        "Product human resource management App is the part of cloud based hrm software.",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-human-resource-management.jpg",
      link: "",
      tags: "Cloud based software",
    },
    {
      name: "Product multi level marketing software App",
      type1: "Software DEVELOPEMNT",
      description:
        "Product multi level marketing App is the part of cloud based mlm software",
      image:
        "https://aaratech.s3.ap-south-1.amazonaws.com/ourprojects/product-multi-level-marketing.jpg",
      link: "",
      tags: "Cloud based software",
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${30}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section
          className="bg-white portfolio-projects section-padding style-1"
          id="MixItUpFCF1BF"
        >
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Custom CRM Development</small>
              <h2 className="mb-20">
                <span>Custom CRM Development</span>
              </h2>
              <p>
                We are providing best Custom CRM Software Development service.
              </p>
            </div>
          </div>
        </section>

        <section className="team section-padding style-6">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">Software</small>
                <h2 className="mb-20">
                  Custom <span> CRM Software Development </span>
                </h2>
                <p>
                  Aara Technologies is the best custom CRM development company
                  in Noida, Lucknow, and the entire of India, we are offering
                  open-source as well as cloud-based CRM and CRM applications
                  also for all types of business. As every business is different
                  from another, one CRM software for all businesses does not
                  suit at all so we are designing and developing CRM for all
                  businesses according to the requirements. For this reason, we
                  are also offering custom CRM development solutions as well. We
                  are having a very solid track record in building very complex
                  and highly scalable business applications like Helpdesk
                  Software and SaaS-based apps.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/amc.webp"
                  className="imgmad mt-30"
                  alt="custom"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-4">
                  <div className="mb-10 img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Business-Automation-Wheel-1.png"
                      alt="custom"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">CRM Development</small>
                        <h2>
                          <span>Automates Business </span> Processes
                        </h2>
                        <br />
                      </div>
                      <p>
                        CRM software is an integral part of any sales. CRM (
                        Customer Relationship Management) software is developed
                        for managing all information like inventory, Organizing
                        leads, and taking your customer services to the next
                        step. We have extensive experience in the integration,
                        implementation, and custom CRM development for small,
                        medium, and large enterprises. Our highly customizable
                        and feature-rich CRM software is developed on leading
                        platforms including Salesforce, Odoo development,
                        OroCRM, and more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="custom"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">CRM Development</small>
                        <h2 className="mb-40">
                          <span>Sales CRM</span>
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        Salesforce CRM software suite does not need any
                        introduction it can be understood by the name. They are
                        the market leaders by multiple folds to any of their
                        peers. Aara Technologies is proud to be Salesforce ISV
                        (Independent Software Vendor) Partner. We offer full
                        Salesforce setup and custom integrations for our
                        customers We have a very solid and well-experienced
                        Salesforce team and have been recognized both by the
                        core Salesforce team as well as Salesforce community.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/cloud.webp"
                      alt="custom"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="custom"
              className="bubble2"
            />
          </div>
          <div className="content trd-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/magento-ui-theme.webp"
                      alt="custom"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info  !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600
                  "
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">CRM Development</small>
                        <h2 className="mb-30">
                          <span>CRM </span> Built-Into <span>ECommerce </span>
                        </h2>
                      </div>
                      <p>
                        This is developed for online store owners, who are not
                        looking for any third-party CRM Software integrations
                        and want to manage customer data within your platform
                        they are worried about security. You can opt for our
                        specialized CRM solutions that are built into eCommerce
                        frameworks. Enabling online store owners to manage
                        leads, track opportunities, manage their Inventory,
                        create users, and manage customer interactions from the
                        back office.
                      </p>
                      <h5 className="fw bold">
                        This comes with several advantages as well:
                      </h5>
                      <br />
                      <ul>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <p>
                            Mobility App Consulting: View Mobility from a New
                            Perspective
                          </p>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <p>Mobile Application Development and Maintenance</p>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <p>
                            User Experience: Design and Testing Laboratories
                          </p>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <p>
                            Automated Mobile Application Testing and Performance
                            Analysis
                          </p>
                        </li>
                        <li className="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <p>Mobile Application Security</p>
                        </li>
                        <li className="d-flex align-items-center">
                          <ArrowRightIcon />
                          <p>Integration with Mobile Advertisement Network</p>
                        </li>
                        <li className="d-flex align-items-center ">
                          <ArrowRightIcon />
                          <p>Mobile Analytics</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="custom"
              className="bubble"
            />
          </div>
        </section>
        <section className="bg-white portfolio-projects section-padding style-1">
          <div className="container">
            <div className="mb-40 text-center section-head style-4">
              <small className="title_small">Custom CRM Development</small>
              <h2 className="mb-20">
                Our <span>Projects</span>
              </h2>
              <p>Here some projects are given below.</p>
            </div>
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {cards.map((card) => (
                    <div className=" col-lg-4 mix security consultation">
                      <div className="text-center portfolio-card mb-30 scalmad !shadow-xl !shadow-gray-400">
                        <div className="img">
                          <LazyLoadImage
                            className=""
                            src={card.image}
                            alt="custom"
                          />
                        </div>
                        <div className="info">
                          <h5>
                            <a href="#">{card.title}</a>
                          </h5>
                          <small className="d-block color-main text-uppercase">
                            {card.title}
                          </small>
                          <div
                            className="overflow-hidden text text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                            }}
                          >
                            {card.content}
                          </div>
                          <div className="tags">
                            <a href="#" className="me-1">
                              {/* {card.tags} */}
                            </a>
                            <a href="#" className="me-1">
                              {/* {card.tags1} */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </section>
      </main>
      <CustomcrmdevelopmentFAQ />
    </div>
  );
};

export default Project;
