import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ApidevelopmentFAQ from "../../../FAQPages/ServicesFAQ/Apidevelopment";
import axios from "axios";
import { baseUrl } from "../../../Config/baseUrl";
const Apidevelopment = (props) => {
  const [meta, setMeta] = useState();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getMeta = () => {
    axios
      .get(`${baseUrl}/blog/meta-api/?page_title=${29}`, {
        headers: {
          Authorization: "Token 3fed23c54f613d0f37284d33bbfb2958960f1063",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setMeta(res?.data?.data);
      });
  };
  useEffect(() => {
    getMeta();
  }, []);
  return (
    <div>
      <Helmet>
        {meta?.map((meta) => (
          <title>{meta.meta_title}</title>
        ))}
      </Helmet>
      {meta?.map((metaItem, index) => (
        <Helmet>
          <meta
            name="title"
            content={metaItem?.meta_title || "Default Title"}
          />
          <meta
            name="description"
            content={metaItem?.meta_description || "Default description"}
          />
          <meta name="meta_tag" content={metaItem?.meta_tag || "Default Tag"} />
          <meta
            name="keywords"
            content={metaItem?.keyword || "Default Keyword"}
          />
          <meta name="fb:admins" content={metaItem?.fb_admin} />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:type"
            content={metaItem?.og_type || "Default type"}
          />
          <meta
            property="og:title"
            content={metaItem?.og_title || "Default Title"}
          />
          <meta property="og:image" content={metaItem?.og_image} />
          <meta
            property="og:description"
            content={metaItem?.og_discription || "Default description"}
          />
          <meta
            property="og:site_name"
            content={metaItem?.og_sitename || "Default Tag"}
          />
          <meta
            property="og:url"
            content={metaItem?.og_url || "Default description"}
          ></meta>
          <link rel="canonical" href={metaItem?.canonical} />
          <meta
            name="twitter:card"
            content={metaItem?.twitter_card || "Default card"}
          />
          <meta name="twitter:site" content={metaItem?.twitter_site}></meta>
          <meta name="twitter:title" content={metaItem?.twitter_title} />
          <meta
            property="twitter:description"
            content={metaItem?.twitter_description || "Default Title"}
          />
          <meta
            property="twitter:image"
            content={metaItem?.twitter_image || "Default description"}
          />
          <link rel="alternate" hreflang="en" href={metaItem.alternate}></link>
          <meta name="robots" content={metaItem?.robots_txt}></meta>
          <meta name="schema" content={metaItem.schema} />
        </Helmet>
      ))}
      <main className="portfolio-page style-1">
        <section className="team section-padding style-1">
          <div className="content1">
            <div className="container">
              <div className="text-center section-head style-4 mb-60">
                <small className="title_small">API development</small>
                <h2 className="mb-20">
                  <span>Application programming interface Development</span>
                </h2>
                <p>
                  Assuming you've listen of the word "API at least a thousand
                  times when you've been in contact with developers of mobile
                  apps Today, I'll discuss everything APIs can do in this
                  article , which covers the basics of API, API development, how
                  to utilize API and how API function and so on. Put on your
                  seatbelts and get ready to go in the world of APIs - - the
                  super heroes behind the operation of mobile apps.
                </p>
                <LazyLoadImage
                  src="https://aaratech.s3.ap-south-1.amazonaws.com/Group-1000006618.jpg"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="about section-padding style-4">
          <div className="content frs-content" id="about" data-scroll-index="2">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-4">
                  <div className="img mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/Group-1000006619.jpg"
                      alt="image"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info   !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">API</small>
                        <h2>
                          <span>What is API and Why Is It Important?</span>
                        </h2>
                        <br />
                      </div>
                      <p>
                        API (Application programming interface software) is an
                        array of instructions and standards that allow a
                        software application to utilize features/services from
                        an additional platform, app or device to offer better
                        service. It's essentially a tool that allows apps to
                        talk with one another.
                      </p>
                      <p>
                        For instance When we press the "Connect Facebook button
                        in Candy Crush, it does not require us to input our
                        Facebook account information. Instead, it pulls
                        information directly from Facebook server and allows us
                        to play - thanks to the API.
                      </p>
                      <p>
                        The API serves as the foundation of all applications
                        that handle data or allow the communication between two
                        products or services. APIs allow mobile applications and
                        platform with the ability to exchange information with
                        other apps or platforms, and make it easier for users to
                        use the app without having to engage developers.
                        Additionally APIs do away with the need to develop an
                        identical application or system from scratch. you can
                        utilize the current one or different app or platform.
                        This is why both business and app developers concentrate
                        upon how to improve the API design process.
                      </p>

                      <p>
                        If you're also looking for an API development framework
                        or would like to get a thorough understanding about it,
                        keep going through the API Development Guide.
                      </p>
                      <p>
                        Before we get into the details we should take an
                        overview of the most basic terms that can help to
                        comprehend the idea.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
          <div className="content sec-content">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="order-2 col-lg-6 order-lg-0 group">
                  <div
                    className="info   !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">Development</small>
                        <h2 className="mb-40">
                          <span>Terms used in API development</span>
                        </h2>
                      </div>
                      <p className="mb-20 text">
                        <ol>
                          <li>
                            <strong>API Key</strong>: When an API request is
                            sent using the use of a header or parameter in order
                            to identify the user the authorization code that is
                            entered into the request is referred to as an API
                            Key.
                          </li>
                          <li>
                            <strong>Endpoint</strong>: When an API connects to
                            another system, the point on this communication path
                            is referred to as Endpoint.
                          </li>
                          <li>
                            <strong>JSON</strong>: JavaScript Object Notion or
                            JSON is believed to be a format for data that APIs
                            use to define parameters for request and the body of
                            the response.
                          </li>
                          <li>
                            <strong>POST</strong>: It's the HTTP method of
                            RESTful API to build resources.
                          </li>
                          <li>
                            <strong> OAuth</strong>: It is basically an Open
                            standard authorization framework that allows access
                            for the user without sharing credentials directly.
                          </li>
                          <li>
                            <strong>REST:</strong> REST (Representational State
                            Transfer) is a architecture for programming that is
                            designed to improve the efficiency of communication
                            between two systems or devices. It is light-weighted
                            and is based on the concept of making specific
                            information available only on request by sharing the
                            references to the data instead of sharing the
                            complete copy of the data. The systems that
                            implement this model are said to be RESTful systems
                            and the best examples of RESTful systems are The
                            World Wide Web.
                          </li>
                          <li>
                            <strong>SOAP</strong>: SOAP or Simple Object Access
                            Protocol is an application protocol that allows for
                            the sharing of structured information during the
                            execution of web service in computer networks. It
                            uses XML information sets and protocol protocols for
                            application layer (like HTTP and SMTP) to format
                            messages and for negotiation and transmission,
                            respectively.
                          </li>
                          <li>
                            <strong> Latency:</strong> It is the duration of
                            time required by API interface during the entire
                            process, from the request up to receiving the
                            answer.
                          </li>
                          <li>
                            <strong> Rate-Limiting:</strong> The phrase API
                            Rate-limiting is the process of determining the rate
                            at which a user can use APIs. It refers to limiting
                            the number of requests that a user can make to an
                            API at a given time.
                          </li>
                          <li>
                            <strong>API Throttling:</strong> This procedure of
                            controlling the use of APIs by users over the
                            specified time frame is known as Throttling. This is
                            a method for API restricting. For instance, you can
                            set a limit of 1000 API request per day. If the user
                            exceeds 1001 requests the server will then send 429
                            messages with an HTTP state to the user with the
                            message "Too many Requests".
                          </li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 order-0 order-lg-2">
                  <div className="img mb-lg-0 ">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/strategy+(2).png"
                      alt="Apidovelopment"
                    />
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble2.png"
              alt="Apidovelopment"
              className="bubble2"
            />
          </div>
          <div className="content trd-content mb-30">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="img mb-30 mb-lg-0">
                    <LazyLoadImage
                      src="https://aaratech.s3.ap-south-1.amazonaws.com/rafiki.jpg"
                      alt="Apidovelopment"
                    />
                  </div>
                </div>
                <div className="col-lg-6 group">
                  <div
                    className="info   !border border-gray-200  rounded-xl transition-transform transform duration-700 hover:scale-105 
                  shadow-2xl !shadow-slate-600 hover:!shadow-purple-600"
                  >
                    <div className="p-4">
                      <div className="section-head style-4">
                        <small className="title_small">HRM</small>
                        <h2 className="mb-30">
                          Efficient <span>interconnect the frontend</span>
                          Processes
                        </h2>
                      </div>
                      <p>
                        Nowthat you are aware of API development, and you are
                        conversant of the terms used in API development, let's
                        dive into the technical aspects beginning with the way
                        API functions and then how to build an API (how to
                        develop the API)?
                      </p>
                      <p>
                        To supply such strict information, the platform made an
                        email for the airline's site to connect to their
                        database and obtain relevant information via API
                        interface. The website replied with the data that API
                        Integration delivered to the platform and then the
                        platform showed it on the screen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoadImage
              src="https://aaratech.s3.ap-south-1.amazonaws.com/about_s4_bubble.png"
              alt="bub"
              className="bubble"
            />
          </div>
        </section>
      </main>
      <ApidevelopmentFAQ />
    </div>
  );
};

export default Apidevelopment;
